import { Box, Button, Chip, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import { http } from '../../api/http'
import AddUser from './AddUser'
import MaterialTable from 'material-table'
import EditUser from './EditUser'
import { useSnackbar } from 'notistack'
import {
	ADMIN_CHIP,
	EXPERT_CHIP,
	QC_EXPERT_CHIP,
	FV_CHIP,
	USER_CHIP,
	roles,
} from '../../shared/constants'

const Users = () => {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [selectedData, setSelectedData] = useState(null)
	const [editOpen, setEditOpen] = useState(false)
	const { enqueueSnackbar } = useSnackbar()

	const renderChip = (roles) => {
		const getStyleByRole = (role) => {
			switch (role) {
				case 'Admin':
					return ADMIN_CHIP
				case 'TechnicalExecutive':
					return FV_CHIP
				case 'Expert':
					return EXPERT_CHIP
				case 'QC Expert':
					return QC_EXPERT_CHIP
				case 'User':
					return USER_CHIP
				default:
					return {}
			}
		}
		return (
			<Box display='flex' gridGap={5} flexWrap='wrap'>
				{roles.map((item) => (
					<Chip
						size='small'
						label={item}
						variant='default'
						style={getStyleByRole(item)}
					/>
				))}
			</Box>
		)
	}

	useEffect(() => {
		getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/users?role=${roles.join(',')}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	function handleDelete(e, rowData) {
		let confirm = window.confirm('Are you sure you want to delete this?')
		if (!confirm) return
		http
			.delete(`/users/${rowData?.id}`)
			.then(() => {
				enqueueSnackbar('User Deleted Successfully', { variant: 'success' })
				getData()
			})
			.catch((err) => console.error('Error Deleting User'))
	}

	return (
		<div className='custom_table'>
			<Box
				mb={2}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='subtitle1'>Users</Typography>
			</Box>
			<Box mb={2} textAlign='right'>
				<Button
					onClick={() => setOpen(true)}
					variant='contained'
					color='primary'
				>
					Add User
				</Button>
			</Box>
			<MaterialTable
				columns={[
					{ title: 'Name', field: 'name' },
					{ title: 'Mobile', field: 'mobile' },
					{
						title: 'Role',
						field: 'role',
						render: (rowData) => renderChip(rowData.role),
					},
				]}
				data={data}
				isLoading={loading}
				title=''
				actions={[
					{
						icon: 'edit',
						onClick: (e, rowData) => {
							setSelectedData(rowData)
							setEditOpen(true)
						},
					},
					{
						icon: 'delete',
						onClick: handleDelete,
					},
				]}
				options={{
					actionsColumnIndex: -1,
				}}
			/>
			<AddUser refresh={getData} open={open} setOpen={setOpen} />
			<EditUser
				refresh={getData}
				data={selectedData}
				open={editOpen}
				setOpen={setEditOpen}
			/>
		</div>
	)
}

export default Users
