import { Box, Button, Dialog, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'

function AddLocation({ open, setOpen, refresh }) {
	const [state, setState] = useState({
		place: '',
		latitude: '',
		longitude: '',
	})
	const { enqueueSnackbar } = useSnackbar()

	function handleSubmit(e) {
		e.preventDefault()
		http
			.post('/disease', state)
			.then((res) => {
				enqueueSnackbar('Hotspot Location Created', { variant: 'success' })
				setOpen(false)
				refresh()
			})
			.catch((err) => {
				enqueueSnackbar('Something went wrong')
			})
	}
	const handleChange = (e) => {
		setState((prev) =>
			setState({
				...prev,
				[e.target.name]: e.target.value,
			})
		)
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<form onSubmit={handleSubmit}>
				<Box p={2}>
					<Typography gutterBottom variant='subtitle1'>
						Add Hotspot Location
					</Typography>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							label='Place'
							name='place'
						/>
					</Box>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							label='Latitude'
							name='latitude'
						/>
					</Box>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							label='Longitude'
							name='longitude'
						/>
					</Box>
					<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							variant='outlined'
						>
							Cancel
						</Button>
						<Button color='primary' variant='contained' type='submit'>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Dialog>
	)
}

export default AddLocation
