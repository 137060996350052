import { Box, MenuItem, TextField } from "@material-ui/core";
import React from "react";

function Filters({
  data,
  selectedAnimal,
  selectedVariant,
  setSelectedAnimal,
  setSelectedVariant,
}) {
  return (
    <Box maxWidth={600} width='100%'>
      <Box mt={2} display='flex' gridGap={10}>
        <TextField
          required
          select
          fullWidth
          variant='outlined'
          size='small'
          value={selectedAnimal}
          onChange={(e) => setSelectedAnimal(e.target.value)}
          label='Animal'>
          {Object.keys(data)?.map((key, index) => (
            <MenuItem value={key}>{key}</MenuItem>
          ))}
        </TextField>
        <TextField
          required
          select
          fullWidth
          variant='outlined'
          size='small'
          value={selectedVariant}
          onChange={(e) => setSelectedVariant(e.target.value)}
          label='Variant'>
          {data[selectedAnimal]?.map((item, index) => (
            <MenuItem value={item?.variety}>{item?.variety}</MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
}

export default Filters;
