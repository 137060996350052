import {Box, Button, Grid, TextField, Typography} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {http} from '../../api/http'
import login from '../../assets/images/login.png'
import logo from '../../assets/images/logo.png'

function Login() {
	const {enqueueSnackbar} = useSnackbar()
	const [data, setData] = useState({
		mobile: '',
		password: '',
	})

	function handleSubmit(e) {
		e.preventDefault()
		http
			.post('/auth/admin/login', data)
			.then((res) => {
				localStorage.setItem('access_token', `Bearer ${res.data.access_token}`)
				window.location.href = '/'
			})
			.catch((err) => {
				enqueueSnackbar('Bad Credentials,Try again', {variant: 'error'})
			})
	}

	function handleOnchange(e) {
		setData({...data, [e.target.name]: e.target.value})
	}

	return (
		<Grid container>
			<Grid item xs={6}>
				<Box height='100vh'>
					<img
						src={login}
						style={{height: '100%', width: '100%', objectFit: 'cover'}}
						alt=''
					/>
				</Box>
			</Grid>
			<Grid item xs={6}>
				<Box
					display='flex'
					minHeight='100vh'
					alignItems='center'
					justifyContent='center'
				>
					<Box maxWidth={500} width='100%' textAlign='center' py={6} px={4}>
						<form onSubmit={handleSubmit}>
							<Box>
								<img className='img_fluid' src={logo} alt='' />
							</Box>
							<Box mt={2}>
								<Typography variant='subtitle1'>
									Login to your account
								</Typography>
							</Box>
							<Box mt={3}>
								<TextField
									onChange={handleOnchange}
									margin='dense'
									variant='outlined'
									label='Mobile'
									name='mobile'
									required
									fullWidth
								/>
							</Box>
							<Box mt={3}>
								<TextField
									onChange={handleOnchange}
									margin='dense'
									variant='outlined'
									label='Password'
									name='password'
									type='password'
									required
									fullWidth
								/>
							</Box>
							<Box mt={3}>
								<Button
									fullWidth
									type='submit'
									margin='dense'
									variant='contained'
									color='primary'
								>
									Login
								</Button>
							</Box>
						</form>
					</Box>
				</Box>
			</Grid>
		</Grid>
	)
}

export default Login
