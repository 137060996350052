import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogContent,
	Typography,
} from '@material-ui/core'
import MaterialTable from 'material-table'
import {useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {http} from '../../api/http'
import NewNotification from '../notifications/New'

const FarmerGroups = () => {
	const [loading, setLoading] = useState(false)
	const [isOpen, setIsOpen] = useState(false)
	const [data, setData] = useState(null)

	const history = useHistory()

	const [selectedGroups, setSelectedGroups] = useState([])

	useEffect(() => {
		getData()
		//eslint-disable-next-line
	}, [])

	async function getData() {
		try {
			setLoading(true)
			const res = await http.get('/group')
			setData(res.data)
			setLoading(false)
		} catch (error) {
			console.log(error)
		}
	}

	if (loading) return <CircularProgress />

	return (
		<div>
			<Box display='flex' justifyContent='space-between' mb={2}>
				<Typography variant='h6' component='h1'>
					Farmer Groups
				</Typography>
				<Link to='/farmer-groups/new'>
					<Button variant='contained' color='primary'>
						Create New Group
					</Button>
				</Link>
			</Box>
			<MaterialTable
				data={data}
				isLoading={loading}
				columns={[{title: 'Group Name', field: 'name'}]}
				actions={[{icon: 'send'}, {icon: 'view'}]}
				components={{
					Action: (props) => {
						if (props.action.icon === 'send') {
							return (
								<Button
									variant='contained'
									color='primary'
									style={{marginLeft: '20px'}}
									onClick={() => {
										setSelectedGroups(props.data)
										setIsOpen(true)
									}}
								>
									Send Message
								</Button>
							)
						}
						if (props.action.icon === 'view') {
							return (
								<Button
									variant='contained'
									color='primary'
									style={{marginLeft: '20px'}}
									disabled={props.data.length > 1}
									onClick={() => {
										history.push(`/farmer-groups/${props.data[0].id}`)
									}}
								>
									View Members
								</Button>
							)
						}
						return 'nothing'
					},
				}}
				title='Groups'
				options={{
					actionsColumnIndex: -1,
					selection: true,
				}}
			/>
			{isOpen && selectedGroups.length > 0 && (
				<Dialog
					open={isOpen}
					onClose={() => setIsOpen(false)}
					style={{padding: '1rem'}}
				>
					<DialogContent>
						<NewNotification selectedGroups={selectedGroups} />
					</DialogContent>
				</Dialog>
			)}
		</div>
	)
}

export default FarmerGroups
