import React, { useEffect } from 'react'
import {
	Box,
	Button,
	CircularProgress,
	Typography,
	TextField,
} from '@material-ui/core'
import { useState } from 'react'
import { http } from '../../api/http'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSnackbar } from 'notistack'
import moment from 'moment'

function DiseaseScanner() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [diseaseNames, setDiseaseNames] = useState([])
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	async function getData() {
		try {
			const [first, second] = await Promise.all([
				http.get('/disease'),
				http.get('/disease/diseases'),
			])
			setLoading(false)

			console.log(first, second)
			setDiseaseNames(
				second.data[0]?.topics?.map((item) => ({
					name: item?.name,
					id: item?.id,
				}))
			)
			setData(first.data)
		} catch (err) {
			console.log(err)
		}
	}

	function handleChange(index, value) {
		let newData = [...data]
		newData[index].diseaseId = value?.map((item) => item?.id)
		setData(newData)
	}

	function handleSubmit() {
		const postData = data.filter((item) => {
			delete item.distopics
			return item
		})
		http
			.put('/disease/update', postData)
			.then((res) => {
				getData()
				enqueueSnackbar('Diseases Updated', { variant: 'success' })
			})
			.catch((err) => {
				console.log(err)
			})
	}

	if (loading) return <CircularProgress />

	return (
		<div className='disease_scanner'>
			<Box
				mb={2}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='subtitle1'>Diseases Listing</Typography>
			</Box>
			<Box maxWidth={600}>
				<table className='disease_scanner_table'>
					<thead>
						<tr>
							<th>Hotspot Name</th>
							<th>Disease Name</th>
						</tr>
					</thead>
					<tbody>
						{data?.map((item, index) => (
							<tr>
								<td style={{ width: '30%' }}>{item?.place}</td>
								<td>
									<Autocomplete
										multiple
										defaultValue={
											item
												? item?.distopics?.map((item) => ({
														name: item?.name,
														id: item?.id,
												  }))
												: ''
										}
										id='tags-outlined'
										options={diseaseNames}
										onChange={(e, newValue) => handleChange(index, newValue)}
										getOptionLabel={(option) => option.name}
										filterSelectedOptions
										size='small'
										renderInput={(params) => (
											<TextField
												{...params}
												variant='outlined'
												label='Select Multiple'
											/>
										)}
									/>
								</td>
							</tr>
						))}
					</tbody>
				</table>
				<Box display='flex' gridGap={10} mt={4} justifyContent='space-between'>
					<Box mt={2}>
						{data?.length ? (
							<Typography style={{ color: 'grey' }} variant='subtitle1'>
								Updated At:
								<span style={{ color: 'red' }}>
									{moment(
										new Date(
											Math.max(...data.map((item) => new Date(item.updatedAt)))
										)
									).format('MMM Do YYYY, hh:mm')}
								</span>
							</Typography>
						) : null}
					</Box>
					<Button onClick={handleSubmit} color='primary' variant='contained'>
						Update Diseases
					</Button>
				</Box>
			</Box>
		</div>
	)
}

export default DiseaseScanner
