import { useEffect, useState } from 'react'
import Timeline from '@material-ui/lab/Timeline'
import TimelineItem from '@material-ui/lab/TimelineItem'
import TimelineSeparator from '@material-ui/lab/TimelineSeparator'
import TimelineConnector from '@material-ui/lab/TimelineConnector'
import TimelineContent from '@material-ui/lab/TimelineContent'
import TimelineDot from '@material-ui/lab/TimelineDot'
import { Box, Chip, CircularProgress, Typography } from '@material-ui/core'
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined'
import { Link, useParams } from 'react-router-dom'
import { http } from '../../../api/http'
import { makeStyles } from '@material-ui/core/styles'
import {
	ADMIN_CHIP,
	EXPERT_CHIP,
	QC_EXPERT_CHIP,
	FV_CHIP,
} from '../../../shared/constants'

import moment from 'moment'

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTimelineItem-root': {
			'&::before': {
				display: 'none',
			},
		},
	},
	dot: {
		padding: '2px',
	},
	icon: {
		fontSize: '15px',
	},
	content: {
		padding: '0 16px ',
		marginBottom: '30px',
	},
	date: {
		lineHeight: '30px',
	},
}))

export default function StepperTimeline({ ticketId }) {
	const [data, setData] = useState()
	const classes = useStyles()

	const [loading, setLoading] = useState(false)
	const [timelineData, setTimelineData] = useState()

	useEffect(() => {
		getData()
	}, [ticketId])

	const getTimeline = (ticketDetails) => {
		let { expertName, statusDetails, qcExpertName } = ticketDetails
		let _statusDetails = statusDetails.map((item) => ({
			...item,
			expertName,
			qcExpertName,
		}))
		setTimelineData(_statusDetails)
	}

	const getData = async () => {
		setLoading(true)
		const res = await http.get(`/request-help/${ticketId}`)
		getTimeline(res.data)
		setData(res.data)
		setLoading(false)
	}

	if (loading) return <CircularProgress />

	return (
		<Box m={2}>
			<Typography variant='h6'>Issue Timeline</Typography>
			<Timeline className={classes.root}>
				{timelineData &&
					timelineData.map((item, idx, arr) => (
						<Step
							key={item.id}
							data={item}
							ticketId={ticketId}
							renderConnector={idx < arr.length - 1}
						/>
					))}
			</Timeline>
			<Box>
				{data?.status === 'Follow Up' && (
					<Box>
						<Typography variant='body2' color='textSecondary'>
							Follow Up
						</Typography>
						<Chip
							size='small'
							label={`Technical Executive : ${data.volunteerName}`}
							variant='default'
							style={FV_CHIP}
						/>
					</Box>
				)}
			</Box>
		</Box>
	)
}

const Step = ({ data, ticketId, renderConnector }) => {
	const classes = useStyles()
	return (
		<>
			<TimelineItem className={classes.step}>
				<TimelineSeparator>
					<TimelineDot
						color='primary'
						variant='outlined'
						className={classes.dot}
					>
						<CheckOutlinedIcon className={classes.icon} />
					</TimelineDot>
					{renderConnector && <TimelineConnector />}
				</TimelineSeparator>
				<TimelineContent className={classes.content}>
					<Box display='flex' gridGap={30} justifyContent='space-between'>
						<Box>
							<Typography variant='subtitle2'>{data.status}</Typography>
							{data.status === 'Accepted' && (
								<Chip
									size='small'
									label={`Expert : ${data.expertName}`}
									variant='default'
									style={EXPERT_CHIP}
								/>
							)}
							{data.status === 'QC Rejected' && (
								<Chip
									size='small'
									label={`QC Expert : ${data.qcExpertName}`}
									variant='default'
									style={QC_EXPERT_CHIP}
								/>
							)}
							{data.status === 'QC Approved' && (
								<Chip
									size='small'
									label={`QC Expert : ${data.qcExpertName}`}
									variant='default'
									style={QC_EXPERT_CHIP}
								/>
							)}
						</Box>
						<Typography
							variant='caption'
							color='textSecondary'
							className={classes.date}
						>
							{moment(data.createdAt).format('MMMM Do YYYY, h:mm a')}
						</Typography>
					</Box>
					{data.status === 'Prescribed' && (
						<Box>
							<Link to={`/prescription/pdf/${ticketId}`}>
								<Typography variant='subtitle2' style={{ color: 'dodgerblue' }}>
									View Prescription
								</Typography>
							</Link>
						</Box>
					)}
				</TimelineContent>
			</TimelineItem>
		</>
	)
}
