import { Box, Button } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { useEffect, useState } from "react";
import { http } from "../../api/http";
import AddCategory from "./AddCategory";
import EditCategory from "./EditCategory";

function Categories() {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    http
      .get("category")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <div className="custom_table">
      <Box mb={2} textAlign="right">
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Category
        </Button>
      </Box>
      <MaterialTable
        columns={[{ title: "Name", field: "name" }]}
        data={data}
        isLoading={loading}
        title="Categories"
        actions={[
          {
            icon: "edit",
            onClick: (e, rowData) => {
              setSelectedData(rowData);
              setEditOpen(true);
            },
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <AddCategory refresh={getData} open={open} setOpen={setOpen} />
      <EditCategory
        data={selectedData}
        refresh={getData}
        open={editOpen}
        setOpen={setEditOpen}
      />
    </div>
  );
}

export default Categories;
