import { Box, Button, Dialog, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'

function EditMedicine({ open, setOpen, data, refresh }) {
	const [name, setName] = useState('')
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		setName(data?.name)
	}, [data])

	function handleSubmit(e) {
		e.preventDefault()
		http
			.put(`/medicine/${data?.id}`, { name })
			.then(() => {
				enqueueSnackbar('Medicine Updated', { variant: 'success' })
				setOpen(false)
				refresh()
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong')
			})
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<form onSubmit={handleSubmit}>
				<Box p={2}>
					<Typography gutterBottom variant='subtitle1'>
						Edit Medicine
					</Typography>
					<Box mt={2}>
						<CustomTextField
							onChange={(e) => setName(e.target.value)}
							required
							value={name}
							label='Medicine Name'
							name='name'
						/>
					</Box>
					<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							variant='outlined'
						>
							Cancel
						</Button>
						<Button color='primary' variant='contained' type='submit'>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Dialog>
	)
}

export default EditMedicine
