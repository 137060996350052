import { Box, Button, Paper, Typography } from '@material-ui/core'
import React from 'react'
import EditableColumn from './EditableColumn'
import _ from 'lodash'
import { http } from '../../api/http'
import { useSnackbar } from 'notistack'
import moment from 'moment'

function PriceTable({ data, selectedVariant, types, selectedAnimal }) {
	const { enqueueSnackbar } = useSnackbar()

	const updatedAt = types[selectedAnimal]?.find(
		(item) => item?.variety == selectedVariant
	)?.pricesUpdatedAt

	let prices = _.groupBy(data, 'unit')
	const updateablePrices = [...data]

	function handleSubmit(e) {
		http
			.put('/market-rates/saveAll', updateablePrices)
			.then((res) => {
				http.put(`/rates-history`, updateablePrices)
				enqueueSnackbar('Prices Updated', { variant: 'success' })
			})
			.catch((err) => {
				enqueueSnackbar('something went wrong', { variant: 'error' })
			})
	}

	return (
		<Box mt={3}>
			<Box
				display='flex'
				alignItems='center'
				mb={2}
				justifyContent='space-between'
			>
				<Typography variant='subtitle2'>
					Price Table{' '}
					<Typography variant='caption' color='textSecondary'>
						(click on a price to edit)
					</Typography>
				</Typography>
				<Button
					onClick={handleSubmit}
					size='small'
					variant='contained'
					color='primary'
				>
					Update Prices
				</Button>
			</Box>
			<Paper>
				<table className='price_table'>
					<thead>
						<tr>
							<th>Count</th>
							<th>Andhra Pradesh</th>
							<th>West Bengal</th>
							<th>India</th>
						</tr>
					</thead>
					<tbody>
						{Object.keys(prices).map((unit, index) => (
							<tr key={index}>
								<td>{unit}</td>
								<EditableColumn
									updateablePrices={updateablePrices}
									data={prices[unit]?.find(
										(item) => item?.place == 'Andhra Pradesh'
									)}
								/>
								<EditableColumn
									updateablePrices={updateablePrices}
									data={prices[unit]?.find(
										(item) => item?.place == 'West Bengal'
									)}
								/>
								<EditableColumn
									updateablePrices={updateablePrices}
									data={prices[unit]?.find((item) => item?.place == 'India')}
								/>
							</tr>
						))}
					</tbody>
				</table>
			</Paper>
			<Box mt={2}>
				{data?.length ? (
					<Typography style={{ color: 'grey' }} variant='subtitle1'>
						Updated At:{' '}
						<span style={{ color: 'red' }}>
							{moment(updatedAt).format('MMM Do YYYY, hh:mm')}
						</span>
					</Typography>
				) : null}
			</Box>
		</Box>
	)
}

export default PriceTable
