import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import ReactQuill from 'react-quill'
import {useHistory, useParams, useRouteMatch} from 'react-router-dom'
import {http} from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'
import CustomUpload from '../../shared/components/CustomUpload'

const swapOrder = (originalArray, originalPosition, newPosition) => {
	originalArray.splice(
		newPosition,
		0,
		originalArray.splice(originalPosition, 1)[0]
	)
	return originalArray
}

function EditPost() {
	const router = useHistory()
	const history = useRouteMatch()
	const params = useParams()
	const {enqueueSnackbar} = useSnackbar()
	const [state, setState] = useState({
		aquaSchool: '',
		name: '',
		description: '',
		coverPhoto: '',
		coverPhotoUrl: '',
		photos: [],
		photosUrl: [],
		videos: [],
		resources: '',
		products: [],
	})

	const [data, setData] = useState({
		categories: [],
		products: [],
	})

	const orderArray = data.categories
		?.map((item) => {
			return item?.topics?.map((topic) => {
				return topic.id
			})
		})
		.find((item) => item.find((inner) => inner == params.id))

	useEffect(() => {
		getData()
	}, [])

	const [videoLink, setVideoLink] = useState('')
	const [loading, setLoading] = useState(false)
	const [order, setOrder] = useState({
		originalPosition: null,
		selectedPosition: null,
	})

	useEffect(() => {
		orderArray &&
			setOrder({
				selectedPosition: orderArray.findIndex((item) => item == params.id),
				originalPosition: orderArray.findIndex((item) => item == params.id),
			})
	}, [data])

	async function getData() {
		try {
			setLoading(true)
			let categories = await http.get('/aqua-school')
			let products = await http.get('/product')
			let post = await http.get(`/aqua-school-topics/${history.params.id}`)
			setData({
				categories: categories.data,
				products: products.data.data,
			})
			setState({
				...post.data,
				// photosUrl: [...post.data.photos],
				aquaSchool: post?.data?.aquaSchool?.id,
				products: post?.data?.productsList?.map((item) => item?.name),
			})
			// setOrder()

			setLoading(false)
		} catch (err) {
			console.error(err)
			setLoading(false)
		}
	}

	function handleVideoLinks(e) {
		setVideoLink(e.target.value)
	}

	function addVideoLink() {
		if (!videoLink) {
			enqueueSnackbar('Video link should not be empty', {variant: 'error'})
			return
		}
		setState({
			...state,
			videos: [...state.videos, videoLink],
		})
		setVideoLink('')
	}

	function deleteVideoLink(idx) {
		let filtered = state.videos?.filter((item, index) => index !== idx)
		setState({
			...state,
			videos: filtered,
		})
	}

	function addImage(v) {
		setState({
			...state,
			photos: [...state.photos, v.key],
			photosUrl: [...state.photosUrl, v.Location],
		})
	}

	function deleteImage(idx) {
		let filtered = state.photos?.filter((item, index) => index !== idx)
		let filteredPhotoUrl = state.photosUrl?.filter(
			(item, index) => index !== idx
		)

		setState({
			...state,
			photos: filtered,
			photosUrl: filteredPhotoUrl,
		})
	}

	function handleResourcesChange(v) {
		setState({
			...state,
			resources: v.replaceAll('<p><br></p>', ''),
		})
	}

	function handleSubmit(e) {
		e.preventDefault()
		if (!state.name || !state.coverPhoto || !state.aquaSchool) {
			enqueueSnackbar('Enter required fields', {variant: 'error'})
			return
		}
		let products = []
		state.products.forEach((product) => {
			let exist = data?.products?.find((item) => item?.name == product)
			if (exist) {
				products.push(exist?.id)
			}
		})
		let postData = {
			...state,
			products,
			resources: state.resources.replaceAll('<p><br></p>', ''),
		}

		const {coverPhotoUrl, ...newData} = postData

		http
			.put(`/aqua-school-topics/${state?.id}`, newData)
			.then((res) => {
				enqueueSnackbar('Aqua School Post Updated', {variant: 'success'})
				router.goBack()
			})
			.catch((err) => {
				enqueueSnackbar('something went wrong', {variant: 'error'})
				console.log(err)
			})
	}

	const handleOrderSubmit = (e) => {
		e.preventDefault()
		const newPositionsArray = swapOrder(
			orderArray,
			order.originalPosition,
			order.selectedPosition
		)

		http
			.patch('/aqua-school-topics/rearrange', newPositionsArray)
			.then(() => {
				enqueueSnackbar('Position of Post Updated', {variant: 'success'})
			})
			.catch((err) => {
				enqueueSnackbar('something went wrong', {variant: 'error'})
			})
	}
	if (loading) return <CircularProgress />

	return (
		<Box maxWidth={600} width='100%' margin='auto'>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography gutterBottom variant='subtitle2'>
					Edit Post
				</Typography>
				<Button onClick={() => router.goBack()} color='primary'>
					Back
				</Button>
			</Box>
			<form onSubmit={handleSubmit}>
				<Box mt={2}>
					<TextField
						select
						fullWidth
						variant='outlined'
						onChange={(e) => setState({...state, aquaSchool: e.target.value})}
						size='small'
						value={state?.aquaSchool}
						label='Category'
					>
						{data?.categories?.map((item) => (
							<MenuItem value={item?.id}>{item?.name}</MenuItem>
						))}
					</TextField>
				</Box>
				<Box mt={3} display='flex' alignItems='center' gridGap={15}>
					<Box flex={3}>
						<CustomTextField
							value={state?.name}
							onChange={(e) => setState({...state, name: e.target.value})}
							label='Name'
						/>
					</Box>

					<Box
						display='flex'
						alignItems='center'
						gridGap={5}
						flex={1}
						width={200}
					>
						<TextField
							select
							fullWidth
							variant='outlined'
							onChange={(e) =>
								setOrder((prev) => ({
									...prev,
									selectedPosition: e.target.value,
								}))
							}
							size='small'
							value={order.selectedPosition}
							label='Position'
						>
							{orderArray?.map((item, index) => (
								<MenuItem value={index}>{index + 1}</MenuItem>
							))}
						</TextField>
						<Button
							onClick={handleOrderSubmit}
							variant='contained'
							color='primary'
						>
							Save
						</Button>
					</Box>
				</Box>
				<Box mt={3}>
					<CustomUpload
						onChange={(v) => {
							console.log('v', v)
							setState({
								...state,
								coverPhoto: v.key,
								coverPhotoUrl: v.Location,
							})
						}}
						label='Upload Cover Image'
						name='image'
					/>
					<Box mt={5} display='flex' flexWrap='wrap' gridGap={10}>
						{state && (
							<Box position='relative'>
								<img
									width='100%'
									height='150px'
									style={{objectFit: 'contain'}}
									src={state.coverPhotoUrl}
									alt={state.description}
								/>
							</Box>
						)}
					</Box>
				</Box>
				<Box mt={3}>
					<CustomTextField
						onChange={(e) => setState({...state, description: e.target.value})}
						label='Description'
						multiline
						value={state?.description}
						rows={5}
					/>
				</Box>
				<Box mt={3}>
					<Typography gutterBottom variant='subtitle2'>
						Resources
					</Typography>
					<ReactQuill
						value={state.resources}
						// onChange={(e) => console.log(e)}
						onChange={handleResourcesChange}
						modules={{
							clipboard: {
								matchVisual: false,
							},
							toolbar: {
								container: [
									[{header: [1, 2, 3, 4, 5, 6, false]}],
									['bold', 'italic', 'underline'],
									[{list: 'ordered'}, {list: 'bullet'}],
									[{align: []}],
									['link', 'image'],
									['clean'],
									[{color: []}],
								],
							},
						}}
					/>
				</Box>
				<Box mt={4}>
					<CustomUpload onChange={addImage} label='Add Images' name='img' />
					<Box mt={2}>
						{state?.photosUrl?.length ? (
							<>
								<Typography variant='caption' color='textSecondary'>
									Added Images:
								</Typography>
								<Box mt={5} display='flex' flexWrap='wrap' gridGap={10}>
									{state?.photosUrl?.map((item, index) => (
										<Box position='relative'>
											<img
												width='100px'
												height='100px'
												style={{objectFit: 'contain'}}
												src={item}
												alt=''
											/>
											<IconButton
												size='small'
												style={{
													color: 'red',
													position: 'absolute',
													top: '-20px',
													right: '-10px',
												}}
												onClick={() => deleteImage(index)}
											>
												<DeleteIcon />
											</IconButton>
										</Box>
									))}
								</Box>
							</>
						) : null}
					</Box>
				</Box>
				<Box mt={4}>
					<Box display='flex' gridGap={2}>
						<CustomTextField
							value={videoLink}
							onChange={handleVideoLinks}
							label='Add Video Links'
						/>
						<Button onClick={addVideoLink} color='primary'>
							Add
						</Button>
					</Box>
					<Box mt={2}>
						{state?.videos?.length ? (
							<Typography variant='caption' color='textSecondary'>
								Added Links:
							</Typography>
						) : null}
						{state?.videos?.map((item, index) => (
							<Box display='flex' alignItems='center' gridGap={2}>
								<Box flex={1}>
									<Typography variant='body2'>{item}</Typography>
								</Box>
								<IconButton onClick={() => deleteVideoLink(index)}>
									<DeleteIcon />
								</IconButton>
							</Box>
						))}
					</Box>
				</Box>
				<Box mt={3}>
					<Autocomplete
						size='small'
						multiple
						options={data?.products?.map((item) => item?.name) || []}
						getOptionLabel={(option) => option}
						onChange={(e, newValue) => setState({...state, products: newValue})}
						value={state?.products || []}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								variant='outlined'
								label='Select Products'
							/>
						)}
					/>
				</Box>
				<Box mt={3} textAlign='right'>
					<Button type='submit' color='primary' variant='contained'>
						Submit Post
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default EditPost
