import { Box, Typography } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

function Post({ data }) {
	return (
		<Link to={`/school/${data?.id}/view`}>
			<Box
				className='cursor_pointer'
				maxWidth={200}
				width='100%'
				height='100%'
				overflow='hidden'
				boxShadow='0px 5px 15px #0000000F'
				borderRadius={10}
			>
				<img
					src={data?.coverPhotoUrl}
					style={{
						minWidth: 240,
						height: 120,
						objectFit: 'cover',
					}}
					alt=''
				/>
				<Box px={2} py={1}>
					<Typography variant='body2' gutterBottom>
						{data?.name}
					</Typography>
				</Box>
			</Box>
		</Link>
	)
}

export default Post
