import { Box, Button, Dialog, Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { http } from "../../api/http";
import CustomTextField from "../../shared/components/CustomTextField";
import CustomUpload from "../../shared/components/CustomUpload";

function AddProduct({ open, setOpen, refresh }) {
  const [state, setState] = useState({
    name: "",
    price: "",
    photo: "",
    category: "",
    purpose: "",
  });
  const { enqueueSnackbar } = useSnackbar();

  function handleSubmit(e) {
    e.preventDefault();
    if (!state.name || !state.price || !state.photo) {
      enqueueSnackbar("Enter required fields", { variant: "error" });
      return;
    }
    http
      .post("/product", state)
      .then((res) => {
        enqueueSnackbar("Product Created", { variant: "success" });
        setOpen(false);
        refresh();
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  return (
    <Dialog open={open} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <Box p={2}>
          <Box textAlign="center">
            <Typography gutterBottom variant="subtitle1">
              Add Product
            </Typography>
          </Box>
          <Box mt={2}>
            <CustomTextField
              onChange={(e) => setState({ ...state, name: e.target.value })}
              label="Name"
              name="name"
            />
          </Box>
          <Box mt={2}>
            <CustomTextField
              onChange={(e) => setState({ ...state, price: e.target.value })}
              label="Price"
              name="price"
              type="number"
            />
          </Box>
          <Box mt={2}>
            <CustomTextField
              onChange={(e) => setState({ ...state, category: e.target.value })}
              label="Category"
              name="category"
            />
          </Box>
          <Box mt={2}>
            <CustomTextField
              onChange={(e) => setState({ ...state, purpose: e.target.value })}
              label="Purpose"
              name="purpose"
            />
          </Box>
          <Box mt={2}>
            <CustomUpload
              onChange={(v) => setState({ ...state, photo: v })}
              label="Upload Photo"
              name="photo"
            />
          </Box>
          <Box display="flex" gridGap={10} justifyContent="flex-end" mt={3}>
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" type="submit">
              Submit
            </Button>
          </Box>
        </Box>
      </form>
    </Dialog>
  );
}

export default AddProduct;
