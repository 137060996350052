import { TextField } from '@material-ui/core'
import React from 'react'

function CustomTextField({ onChange = () => {}, ...props }) {
	return (
		<TextField
			label={props.label}
			name={props.name}
			onChange={onChange}
			fullWidth
			variant='outlined'
			size='small'
			{...props}
		/>
	)
}

export default CustomTextField
