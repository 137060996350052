import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

function AnimalCard({ data, animal }) {
	const router = useHistory()

	return (
		<Box
			onClick={() =>
				router.push(
					`/market-prices/update?animal=${animal}&variant=${
						data?.variety
					}&date=${moment().format('yyyy-MM-DD')}`
				)
			}
			className='cursor_pointer'
			textAlign='center'
			boxShadow='0px 5px 15px #0000000F'
			px={2}
			py={1}
		>
			<img
				src={data.photoUrl}
				style={{ width: 120, height: 100, objectFit: 'contain' }}
				alt=''
			/>
			<Typography variant='body2'>{data?.variety}</Typography>
		</Box>
	)
}

export default AnimalCard
