import {Box, CircularProgress, Typography} from '@material-ui/core'
import {useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {http} from '../../../api/http'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import headerLogo from '../../../assets/images/Royye_pdf.png'
import footerImg from '../../../assets/images/pdf_footer.png'
import moment from 'moment'

const Pdf = () => {
	const {id: ticketId} = useParams()
	const [ticketDetails, setTicketDetails] = useState()
	const [prescriptions, setPrescriptions] = useState()
	const [expertDetails, setExpertDetails] = useState()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		setLoading(true)
		const [first, second] = await Promise.all([
			http.get(`/request-help/${ticketId}`),
			http.get(`/prescription/request-help/${ticketId}`),
		])
		setTicketDetails(first.data)
		setPrescriptions(second.data)
		const res = await http.get(`/users/expert/profile/${first.data?.expertId}`)
		setExpertDetails(res.data)
		setLoading(false)
	}

	if (loading) return <CircularProgress />
	return (
		<div>
			<Box
				display='flex'
				alignItems='center'
				justifyContent='space-between'
				mb={2}
			>
				<Box display='flex' alignItems='center' gridGap={20}>
					<Link style={{lineHeight: 0}} to={`/tickets/?status=active`}>
						<KeyboardBackspaceIcon />
					</Link>
					<Typography display='inline'>{`Prescription Preview`}</Typography>
				</Box>
			</Box>
			<div className='' id='pdf'>
				<div className='' id='pdf_page1'>
					<PdfTemplate
						ticketDetails={ticketDetails}
						expertDetails={expertDetails}
					>
						<section id='pdf_page1'>
							<div className='presc'>
								<h3 className='presc__heading'>ఔషధ పత్రం :</h3>
								<div className='presc__box'>
									<div className='presc__box-row'>
										<div className='farmer-item'>
											<span className='farmer-item__heading'>సమస్య : </span>
											<span>
												{ticketDetails?.issuesDetails.map(
													(item) => `${item.name}`
												)}
											</span>
										</div>
										<div className='farmer-item'>
											<span className='farmer-item__heading'>Date : </span>
											<span>{moment(Date.now()).format('DD-MM-YYYY')}</span>
										</div>
									</div>
									<ul className='presc-list'>
										{prescriptions?.slice(0, 6).map((item) => {
											return (
												<li key={item.id} className='presc-item'>
													<span>{item.medicine + ' - '}</span>
													<span>{item.quantity}</span>
													<span>{item.units + ','}</span>
													<span>{item.medicineComposition}</span>
												</li>
											)
										})}
									</ul>
								</div>
							</div>
						</section>
					</PdfTemplate>
				</div>
				{prescriptions?.length > 6 && (
					<div className='' id='pdf_page_new'>
						<PdfTemplate
							ticketDetails={ticketDetails}
							expertDetails={expertDetails}
						>
							<section id='pdf_page1'>
								<div className='presc'>
									<h3 className='presc__heading'>ఔషధ పత్రం :</h3>
									<div className='presc__box'>
										<div className='presc__box-row'>
											<div className='farmer-item'>
												<span className='farmer-item__heading'>సమస్య : </span>
												<span>
													{ticketDetails?.issuesDetails.map(
														(item) => `${item.name}`
													)}
												</span>
											</div>
											<div className='farmer-item'>
												<span className='farmer-item__heading'>Date : </span>
												<span>{moment(Date.now()).format('DD-MM-YYYY')}</span>
											</div>
										</div>
										<ul className='presc-list'>
											{prescriptions?.slice(6).map((item) => {
												return (
													<li key={item.id} className='presc-item'>
														<span>{item.medicine + ' - '}</span>
														<span>{item.quantity}</span>
														<span>{item.units + ','}</span>
														<span>{item.medicineComposition}</span>
													</li>
												)
											})}
										</ul>
									</div>
								</div>
							</section>
						</PdfTemplate>
					</div>
				)}
				<div className='' id='pdf_page2'>
					<PdfTemplate
						ticketDetails={ticketDetails}
						expertDetails={expertDetails}
					>
						<div className='presc__box'>
							<h3>అధిక దిగుబడులు రావడానికి పాటించవలసిన సూచనలు & మందులు:-</h3>
							<ol>
								<li>
									7 రోజులకు ఒక్కసారి తప్పనిసరిగా వాటర్ టెస్టింగ్ చెయ్యించాలి.
								</li>
								<li>
									ప్రతి అష్టమి అమావాస్య పౌర్ణమి రెండు రోజులు ముందు హార్డన్స్
									(కఠినత ) అధికంగా ఉంటే ZN మ్యాట్రిక్ (EDTA)- 2-5
									కిలోలు/ఎకరానికి నీటిలో కలపాలి.
								</li>
								<li>
									ప్రతి అష్టమి అమావాస్య పౌర్ణమి ఒక్క రోజు ముందు ఆర్గానిక్
									కాన్సాన్ ట్రేటిడ్ మినరల్స్ అయినా "గోల్డ్ మిన్" ని 10 కిలోలు/
									ఎకరానికి నీటిలో కలపాలి.
								</li>
								<li>
									చెరువు పరిస్థితిని బట్టి 7 రోజులకి ఒకసారి ప్రోబైయటిక్ ఐన
									"పయోనీర్" నీ 250 గ్రాములు/ ఎకరానికి, 3 కిలోలు బెల్లంతో
									కలుపుకుని, 4 గంటలు నానబెట్టి ఉదయం 9 గంటల లోపు పాండ్లో
									చల్లుకోవాలి.{' '}
								</li>
								<li>
									పిల్ల వదిలిన 25 రోజుల నుండి రొయ్యల్లో ఫీడ్ సామర్థ్యం
									పెరగడానికి, అలాగే వైట్ గట్ రాకుండా అడ్వాన్స్ గా సాయంత్రం పూట
									ఫీడ్ తో పాటు " ఏంజోలాక్" ని ఫీడ్ కీ 10 గ్రాములు చొప్పున
									ఇచ్చుకోవాలి
								</li>
								<li>
									గమనిక : పై సూచనలు పాటిస్తూ, మా ఎక్స్పర్ట్ సూచించిన మందులు
									వాడితే మన రొయ్యరాజు రైతులు నుంచి మంచి దిగుబడులు సాధించడం
									జరుగుతుంది.{' '}
								</li>
							</ol>
						</div>
					</PdfTemplate>
				</div>
			</div>
		</div>
	)
}

export default Pdf

const PdfTemplate = ({ticketDetails, children, expertDetails}) => {
	return (
		<section className='pdf'>
			<header>
				<img className='pdf-header__logo' src={headerLogo} alt='' />
				<div className='pdf-header__text'>
					<div className=''>
						<span style={{fontSize: '18px', fontWeight: 'bold'}}>
							{ticketDetails?.expertName}
						</span>
						<span>{`(${expertDetails?.qualification})`}</span>
						<span>{expertDetails?.designation}</span>
						<span>
							<span style={{fontWeight: 'bold'}}>Experience: </span>
							{expertDetails?.experience}
						</span>
					</div>
					<p className='pdf-header__text-tollfree'>
						ఉచిత టోల్ ఫ్రీ :- 1800 12399 3333
					</p>
				</div>
			</header>

			<main>
				<section className='farmer'>
					<div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Farmer Name : </span>
							<span>{ticketDetails?.name}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Farmer Mobile Number :{' '}
							</span>
							<span>{ticketDetails?.mobile}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Village / Area : </span>
							<span>{ticketDetails?.address}</span>
						</div>
					</div>
					<div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Issue Ticket Number :{' '}
							</span>
							<span>{ticketDetails?.ticketId}</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>
								Farmer Total Acreage :
							</span>
							<span>
								{ticketDetails?.pondDetails
									.map((item) => +item.numberOfAcres)
									.reduce((prev, curr) => prev + curr, 0)}
								{` Acres`}
							</span>
						</div>
						<div className='farmer-item'>
							<span className='farmer-item__heading'>Date : </span>
							<span>{moment(Date.now()).format('DD-MM-YYYY')}</span>
						</div>
					</div>
				</section>
				{children}
			</main>
			<footer>
				<img src='/pdf_footer.png' alt='' />
			</footer>
		</section>
	)
}
