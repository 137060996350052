import React from 'react'
import {Redirect, Switch} from 'react-router-dom'
import Categories from '../pages/categories'
import Farmers from '../pages/farmers'
import FarmerDetails from '../pages/farmers/FarmerDetails'
import Feed from '../pages/feed'
import AddPost from '../pages/feed/AddPost'
import AddSchoolPost from '../pages/school/AddPost'
import View from '../pages/school/View'
import EditSchoolPost from '../pages/school/EditPost'
import EditPost from '../pages/feed/EditPost'
import MarketPrices from '../pages/marketprices'
import UpdatePrices from '../pages/marketprices/UpdatePrices'
import School from '../pages/school'
import Tickets from '../pages/tickets'
import Topics from '../pages/topics'
import Tags from '../pages/tags'
import Products from '../pages/products'
import DiseaseScanner from '../pages/DiseaseScanner'
import Users from '../pages/users'
import ProtectedRoute from '../shared/components/ProtectedRoute'
import Pdf from '../pages/tickets/prescription/Pdf'
import Medicine from '../pages/medicine'
import MedicineComposition from '../pages/medicine-composition'
import TicketDetails from '../pages/tickets/active-tickets/TicketDetails'
import Symptom from '../pages/symptoms'
import FarmerGroups from '../pages/farmer-groups'
import Notifications from '../pages/notifications'
import NewNotification from '../pages/notifications/New'
import Members from '../pages/farmer-groups/Members'
import CreateNewGroup from '../pages/farmer-groups/CreateNewGroup'
import HotspotLocations from '../pages/hotspot-locations'
import AddFarmerToGroup from '../pages/farmer-groups/AddFarmerToGroup'

function Routes() {
	return (
		<Switch>
			<ProtectedRoute path='/feed/:id/edit' component={EditPost} />
			<ProtectedRoute path='/tickets/details/:id' component={TicketDetails} />
			<ProtectedRoute
				path='/farmer-groups/:id/add-farmers'
				component={AddFarmerToGroup}
			/>
			<ProtectedRoute path='/farmer-groups/new' component={CreateNewGroup} />
			<ProtectedRoute path='/farmer-groups/:id' component={Members} />
			<ProtectedRoute path='/farmer-groups' component={FarmerGroups} />
			<ProtectedRoute path='/notifications' component={Notifications} />
			<ProtectedRoute path='/new-notification' component={NewNotification} />
			<ProtectedRoute path='/tickets' component={Tickets} />
			<ProtectedRoute path='/prescription/pdf/:id' component={Pdf} />
			<ProtectedRoute path='/feed/add' component={AddPost} />
			<ProtectedRoute path='/feed' component={Feed} />
			<ProtectedRoute path='/school/:id/edit' component={EditSchoolPost} />
			<ProtectedRoute path='/school/:id/view' component={View} />
			<ProtectedRoute path='/school/add' component={AddSchoolPost} />
			<ProtectedRoute path='/school' component={School} />
			<ProtectedRoute path='/products' component={Products} />
			<ProtectedRoute path='/farmers/:id' component={FarmerDetails} />
			<ProtectedRoute path='/farmers' component={Farmers} />
			<ProtectedRoute path='/market-prices/update' component={UpdatePrices} />
			<ProtectedRoute path='/market-prices' component={MarketPrices} />
			<ProtectedRoute path='/disease-scanner' component={DiseaseScanner} />
			<ProtectedRoute path='/categories' component={Categories} />
			<ProtectedRoute path='/topics' component={Topics} />
			<ProtectedRoute path='/medicine' component={Medicine} />
			<ProtectedRoute path='/symptoms' component={Symptom} />
			<ProtectedRoute path='/hotspot-locations' component={HotspotLocations} />

			<ProtectedRoute
				path='/medicine-composition'
				component={MedicineComposition}
			/>
			<ProtectedRoute path='/users' component={Users} />
			<ProtectedRoute path='/tags' component={Tags} />
			<ProtectedRoute
				exact
				path='/'
				render={() => <Redirect to='/farmers' />}
			/>
		</Switch>
	)
}

export default Routes
