import {Box, Button, Dialog, Typography} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {http} from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'
import {roles} from '../../shared/constants'

const EditUser = ({data, open, setOpen, refresh}) => {
	const {enqueueSnackbar} = useSnackbar()
	const [user, setUser] = useState({})

	useEffect(() => {
		setUser(data)
	}, [data])

	async function handleSubmit(e) {
		e.preventDefault()
		try {
			if (['Expert', 'QC Expert'].every((i) => user.role.includes(i))) {
				return enqueueSnackbar(
					'User cannot have both Expert and QC Expert Roles',
					{variant: 'error'}
				)
			}
			const res = await http.patch(`/users/${user.id}`, user)

			res && enqueueSnackbar('User Updated', {variant: 'success'})
			setOpen(false)
			refresh()
		} catch (err) {
			enqueueSnackbar('Error Updating User')
		}
	}

	function handleChange(e) {
		setUser({
			...user,
			[e.target.name]: e.target.value,
		})
	}

	return (
		<>
			{data && (
				<div>
					<Dialog open={open} maxWidth='sm' fullWidth>
						<form onSubmit={handleSubmit}>
							<Box p={2}>
								<Typography gutterBottom variant='subtitle1'>
									Edit User
								</Typography>
								<Box mt={2} display='flex' flexDirection='column' gridGap={20}>
									<CustomTextField
										onChange={handleChange}
										value={user?.name}
										required
										label='Name'
										name='name'
									/>
									<CustomTextField
										onChange={handleChange}
										value={user?.mobile}
										required
										label='Mobile'
										name='mobile'
									/>
									<CustomTextField
										onChange={handleChange}
										label='New Password'
										name='password'
									/>
									{user && (
										<Box>
											<Autocomplete
												multiple
												options={roles}
												getOptionLabel={(option) => option}
												size='small'
												defaultValue={user.role}
												getOptionSelected={(option, value) => option === value}
												onChange={(e, newValue) =>
													setUser((prev) => ({
														...prev,
														role: [...newValue],
													}))
												}
												renderInput={(params) => (
													<CustomTextField
														{...params}
														required={false}
														label='Select Group(s)'
													/>
												)}
											/>
										</Box>
									)}
								</Box>
								<Box
									display='flex'
									gridGap={10}
									justifyContent='flex-end'
									mt={3}
								>
									<Button
										onClick={() => setOpen(false)}
										color='primary'
										variant='outlined'
									>
										Cancel
									</Button>
									<Button color='primary' variant='contained' type='submit'>
										Submit
									</Button>
								</Box>
							</Box>
						</form>
					</Dialog>
				</div>
			)}
		</>
	)
}

export default EditUser
