import React from "react";
import { Box, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { http } from "../../api/http";
import { useSnackbar } from "notistack";

function Product({ data, setSelectedData, setEditOpen, refresh }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleEdit() {
    setEditOpen(true);
    setSelectedData(data);
    setAnchorEl(null);
  }

  function handleDelete() {
    let confirm = window.confirm("Are you sure you want to delete this?");
    if (!confirm) return;
    http
      .delete(`/product/${data?.id}`)
      .then((res) => {
        enqueueSnackbar("Product Deleted", { variant: "success" });
        refresh();
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong", { variant: "error" });
      });
  }

  return (
    <>
      <Box
        className="cursor_pointer"
        width="100%"
        overflow="hidden"
        boxShadow="0px 5px 15px #0000000F"
        borderRadius={10}
      >
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="subtitle2" gutterBottom>
            {data?.name}
          </Typography>
          <div>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </div>
        </Box>
        <img
          src={data?.photoUrl}
          style={{
            width: "100%",
            height: 200,
            objectFit: "cover",
          }}
          alt=""
        />
        <Box px={2} py={1}>
          <Typography variant="body2" gutterBottom>
            &#8377; {data?.price} /-
          </Typography>{" "}
          <Typography variant="body2" gutterBottom>
            {data?.category}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {data?.purpose}
          </Typography>
        </Box>
      </Box>
    </>
  );
}

export default Product;
