import { Box, Button, Typography } from '@material-ui/core'
import React, { useState, useEffect } from 'react'
import Filters from './Filters'
import PriceTable from './PriceTable'
import { http } from '../../api/http'
import { useHistory, useLocation } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'

function UpdatePrices() {
	const router = useHistory()
	const [types, setTypes] = useState({})
	const [prices, setPrices] = useState([])
	const [loading, setLoading] = useState(false)
	const [selectedAnimal, setSelectedAnimal] = useState('')
	const [selectedVariant, setSelectedVariant] = useState('')
	const [pricesLoading, setPricesLoading] = useState(false)

	const query = new URLSearchParams(useLocation().search)

	useEffect(() => {
		setSelectedAnimal(query.get('animal'))
		setSelectedVariant(query.get('variant'))
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		getTypes()
		//eslint-disable-next-line
	}, [])

	useEffect(() => {
		getPrices()
	}, [selectedAnimal, selectedVariant])

	function getTypes() {
		setLoading(true)
		http
			.get('/market/types')
			.then((res) => {
				setTypes(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	function getPrices() {
		setPricesLoading(true)
		http
			.get('/market-rates', {
				params: {
					type: selectedAnimal || query.get('animal'),
					variety: selectedVariant || query.get('variant'),
				},
			})
			.then((res) => {
				setPrices(res.data)
				setPricesLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	if (loading || pricesLoading) return <CircularProgress />
	return (
		<>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography variant='subtitle2'>All Prices</Typography>
				<Button onClick={() => router.goBack()} color='primary'>
					Back
				</Button>
			</Box>
			<Filters
				data={types}
				selectedVariant={selectedVariant}
				setSelectedVariant={setSelectedVariant}
				selectedAnimal={selectedAnimal}
				setSelectedAnimal={setSelectedAnimal}
			/>
			<PriceTable
				selectedVariant={selectedVariant}
				selectedAnimal={selectedAnimal}
				types={types}
				data={prices || []}
			/>
		</>
	)
}

export default UpdatePrices
