import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { SnackbarProvider } from 'notistack'
import AuthProvider from './context/AuthContext'
ReactDOM.render(
	<AuthProvider>
		<SnackbarProvider
			anchorOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
			maxSnack={3}
		>
			<App />
		</SnackbarProvider>
	</AuthProvider>,
	document.getElementById('root')
)
