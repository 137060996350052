import { Box, Typography } from '@material-ui/core'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { http } from '../../api/http'
import AnimalCard from './AnimalCard'

function MarktePrices() {
	const [types, setTypes] = useState({})

	useEffect(() => {
		http
			.get('/market/types')
			.then((res) => {
				setTypes(res.data)
			})
			.catch((err) => {
				console.log(err)
			})
	}, [])

	return (
		<div>
			<Typography gutterBottom variant='h6'>
				Market Prices
			</Typography>
			<Typography variant='caption' color='textSecondary'>
				Select an animal to enter rates
			</Typography>
			{Object.keys(types)?.map((key, index) => (
				<Box mt={2} key={index}>
					<Typography color='primary' gutterBottom variant='subtitle2'>
						{key}
					</Typography>
					<Box display='flex' flexWrap='wrap' gridGap={20}>
						{types[key]?.map((item, index) => (
							<AnimalCard animal={key} data={item} index={index} />
						))}
					</Box>
				</Box>
			))}
		</div>
	)
}

export default MarktePrices
