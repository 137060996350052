import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {
	Box,
	Button,
	CircularProgress,
	Dialog,
	Typography,
} from '@material-ui/core'
import {http} from '../../api/http'
import {useSnackbar} from 'notistack'
import {Link, useHistory} from 'react-router-dom'
import CustomTextField from '../../shared/components/CustomTextField'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

function Members() {
	const [open, setOpen] = useState(false)
	const [data, setData] = useState([])
	const history = useHistory()
	const [selectedFarmers, setSelectedFarmers] = useState([])
	const [groupName, setGroupName] = useState('')
	const [loading, setLoading] = useState(false)
	const {enqueueSnackbar} = useSnackbar()

	useEffect(() => {
		getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/users?role=User`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	function handleNewgroup(e, rowData) {
		e.preventDefault()
		http
			.post(`/group`, {name: groupName, farmerIds: selectedFarmers})
			.then((res) => {
				setOpen(false)
				enqueueSnackbar('Group Created', {variant: 'success'})
				history.push('/farmer-groups')
			})
			.catch((err) => {
				enqueueSnackbar('Something went wrong')
			})
	}

	if (loading) return <CircularProgress />

	return (
		<div className='custom_table'>
			<Box display='flex' alignItems='center' gridGap={10} mb={2}>
				<Link
					color='textPrimary'
					onClick={() => history.goBack()}
					style={{cursor: 'pointer', display: 'inline-block'}}
				>
					<KeyboardBackspaceIcon fontSize='medium' style={{marginTop: '5px'}} />
				</Link>
				<Typography variant='h5'>Create New Group</Typography>
			</Box>
			<MaterialTable
				columns={[
					{title: 'Farmer Name', field: 'name'},
					{title: 'Mobile', field: 'mobile'},
					{
						title: 'Address',
						field: 'address',
					},
					{
						title: 'City',
						render: (rowData) => rowData.address?.split(',').slice(-3)[0],
					},
				]}
				data={data}
				isLoading={loading}
				title='Farmers'
				components={{
					Action: (props) => {
						if (props.action.icon === 'add') {
							return (
								<Button
									variant='contained'
									color='primary'
									onClick={() => {
										setSelectedFarmers(props.data?.map((item) => item.id))
										setOpen(true)
									}}
								>
									Create A Group
								</Button>
							)
						}
					},
				}}
				actions={[
					{
						icon: 'add',
					},
				]}
				options={{
					actionsColumnIndex: -1,
					selection: true,
				}}
			/>
			{open && (
				<Dialog open={open} maxWidth='sm' fullWidth>
					<form onSubmit={handleNewgroup}>
						<Box p={2}>
							<Typography gutterBottom variant='subtitle1'>
								Add Farmer To The Group
							</Typography>
							<Box mt={2}>
								<CustomTextField
									onChange={(e) => setGroupName(e.target.value)}
									required
									label='Group Name'
									name='name'
									value={groupName}
								/>
							</Box>
							<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
								<Button
									onClick={() => setOpen(false)}
									color='primary'
									variant='outlined'
								>
									Cancel
								</Button>
								<Button color='primary' variant='contained' type='submit'>
									Submit
								</Button>
							</Box>
						</Box>
					</form>
				</Dialog>
			)}
		</div>
	)
}

export default Members
