import { Box, Button, Dialog, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'

function AddTopic({ open, setOpen, refresh }) {
	const [name, setName] = useState('')
	const { enqueueSnackbar } = useSnackbar()

	function handleSubmit(e) {
		e.preventDefault()
		http
			.post('/aqua-school', { name })
			.then((res) => {
				enqueueSnackbar('Aqua School Topic Created', { variant: 'success' })
				setOpen(false)
				refresh()
			})
			.catch((err) => {
				enqueueSnackbar('Something went wrong')
			})
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<form onSubmit={handleSubmit}>
				<Box p={2}>
					<Typography gutterBottom variant='subtitle1'>
						Add Topic
					</Typography>
					<Box mt={2}>
						<CustomTextField
							onChange={(e) => setName(e.target.value)}
							required
							label='Name'
							name='name'
						/>
					</Box>
					<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							variant='outlined'
						>
							Cancel
						</Button>
						<Button color='primary' variant='contained' type='submit'>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Dialog>
	)
}

export default AddTopic
