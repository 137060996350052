import {Box, Button, Dialog, Typography} from '@material-ui/core'
import {useSnackbar} from 'notistack'
import React, {useState} from 'react'
import {http} from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'

function AddMember({open, setOpen, refresh}) {
	const [name, setName] = useState('')
	const {enqueueSnackbar} = useSnackbar()

	function handleSubmit(e) {
		e.preventDefault()
		http
			.post(``)
			.then(() => {
				enqueueSnackbar('Farmer Added To the Group', {variant: 'success'})
				setOpen(false)
				refresh()
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong')
			})
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<form onSubmit={handleSubmit}>
				<Box p={2}>
					<Typography gutterBottom variant='subtitle1'>
						Add Farmer To The Group
					</Typography>
					<Box mt={2}>
						<CustomTextField
							onChange={(e) => setName(e.target.value)}
							required
							label='Farmer Name'
							name='name'
							value={name}
						/>
					</Box>
					<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							variant='outlined'
						>
							Cancel
						</Button>
						<Button color='primary' variant='contained' type='submit'>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Dialog>
	)
}

export default AddMember
