import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'
import CustomUpload from '../../shared/components/CustomUpload'

function AddPost() {
	const { enqueueSnackbar } = useSnackbar()
	const router = useHistory()
	const [state, setState] = useState({
		category: '',
		content: '',
		file: [],
		fileInput: [],
		videoUrl: [],
		tags: [],
	})

	console.log(state)
	const [data, setData] = useState({
		categories: [],
		tags: [],
	})
	const [loading, setLoading] = useState(false)
	const [mediaType, setMediaType] = useState('')
	const [videoUrl, setVideoUrl] = useState('')

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		try {
			setLoading(true)
			let categories = await http.get('/category')
			let tags = await http.get('/tags')
			setData({
				categories: categories.data,
				tags: tags.data,
			})
			setLoading(false)
		} catch (err) {
			console.log(err)
		}
	}

	function handleChange(e) {
		setState({
			...state,
			[e.target.name]: e.target.value,
		})
	}

	function addVideoUrl() {
		if (!videoUrl) {
			enqueueSnackbar('Video Url is required', { variant: 'error' })
			return
		}
		setState({
			...state,
			videoUrl: [...state.videoUrl, videoUrl],
		})
		setVideoUrl('')
	}

	function deleteVideoUrl(index) {
		let filtered = [...state.videoUrl].filter((item, idx) => idx !== index)
		setState({
			...state,
			videoUrl: filtered,
		})
	}

	function addImage(v) {
		console.log(v)
		setState({
			...state,
			file: [...state.file, v.key],
			fileInput: [...state.fileInput, v.Location],
		})
	}

	function deleteImage(index) {
		let filtered = [...state.file].filter((item, idx) => idx !== index)
		let fileFilter = [...state.fileInput].filter((item, idx) => idx !== index)
		setState({
			...state,
			file: filtered,
			fileInput: fileFilter,
		})
	}

	function handleSubmit(e) {
		e.preventDefault()
		if (!state.category || !state.content) {
			enqueueSnackbar('Enter Category and content', { variant: 'error' })
			return
		}
		if (!state.file.length && !state.videoUrl.length) {
			enqueueSnackbar('image or video is required', { variant: 'error' })
			return
		}
		http
			.post('/feed', state)
			.then((res) => {
				enqueueSnackbar('Post Created', { variant: 'success' })
				router.push('/feed')
			})
			.catch((err) => {
				enqueueSnackbar('something went wrong', { variant: 'error' })
			})
	}

	if (loading) return <CircularProgress />

	return (
		<Box maxWidth={600} width='100%'>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography gutterBottom variant='subtitle2'>
					Add a post
				</Typography>
				<Button onClick={() => router.goBack()} color='primary'>
					Back
				</Button>
			</Box>
			<form onSubmit={handleSubmit}>
				<Box mt={2}>
					<TextField
						select
						fullWidth
						variant='outlined'
						name='category'
						onChange={handleChange}
						size='small'
						label='Category'
					>
						{data?.categories?.map((item, index) => (
							<MenuItem value={item?.name} key={index}>
								{item?.name}
							</MenuItem>
						))}
					</TextField>
				</Box>
				<Box mt={2}>
					<CustomTextField
						onChange={handleChange}
						name='content'
						label='Content'
						multiline
						rows={5}
					/>
				</Box>
				<Box mt={3}>
					<Autocomplete
						size='small'
						multiple
						onChange={(e, newValue) => setState({ ...state, tags: newValue })}
						options={data?.tags?.map((item) => item?.name)}
						getOptionLabel={(option) => option}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField {...params} variant='outlined' label='Tags' />
						)}
					/>
				</Box>
				<Box mt={2}>
					<TextField
						select
						fullWidth
						variant='outlined'
						onChange={(e) => setMediaType(e.target.value)}
						size='small'
						name='mediaTy'
						label='Media Type'
					>
						<MenuItem value='image'>Image</MenuItem>
						<MenuItem value='video'>Video</MenuItem>
					</TextField>
				</Box>
				{mediaType === 'image' && (
					<Box mt={2}>
						<CustomUpload
							onChange={addImage}
							label='Upload Images'
							name='image'
						/>
						<Box mt={5} display='flex' flexWrap='wrap' gridGap={10}>
							{state?.fileInput?.map((item, index) => (
								<Box position='relative'>
									<img
										width='100px'
										height='100px'
										style={{ objectFit: 'cover' }}
										src={item}
										alt=''
									/>
									<IconButton
										size='small'
										style={{
											color: 'red',
											position: 'absolute',
											top: '-20px',
											right: '-10px',
										}}
										onClick={() => deleteImage(index)}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							))}
						</Box>
					</Box>
				)}
				{mediaType === 'video' && (
					<Box>
						<Box mt={2} display='flex' gridGap={10}>
							<CustomTextField
								onChange={(e) => setVideoUrl(e.target.value)}
								name='videoUrl'
								value={videoUrl}
								label='Video Url'
							/>
							<Button
								size='small'
								variant='outlined'
								color='primary'
								onClick={addVideoUrl}
							>
								Add
							</Button>
						</Box>
						<Box mt={2}>
							{state?.videoUrl?.map((item, index) => (
								<Box display='flex'>
									<Box flex={1} alignItems='center'>
										<Typography>{item}</Typography>
									</Box>
									<IconButton onClick={() => deleteVideoUrl(index)}>
										<DeleteIcon />
									</IconButton>
								</Box>
							))}
						</Box>
					</Box>
				)}

				<Box mt={3} textAlign='right'>
					<Button type='submit' color='primary' variant='contained'>
						Submit Post
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default AddPost
