import React, { useEffect, useRef } from "react";

function EditableColumn({ data, updateablePrices }) {
  const columnRef = useRef(null);

  const config = {
    attributes: true,
    childList: true,
    subtree: true,
    characterData: true,
  };

  useEffect(() => {
    const callback = (mutationsList, observer) => {
      let targetId = columnRef.current.getAttribute("data-target");
      let targetContent = columnRef.current.textContent;
      let filtered = [...updateablePrices]?.filter(
        (item) => item.id != targetId
      );
      let current = [...updateablePrices]?.find((item) => item.id == targetId);
      current.price = +targetContent;
      let newPrices = [...filtered, current];
      updateablePrices = newPrices;
    };
    const observer = new MutationObserver(callback);
    observer.observe(columnRef.current, config);
    return () => {
      observer.disconnect();
    };
  });

  function handleChange(e) {
    console.log(String.fromCharCode(e.which));
    if (isNaN(String.fromCharCode(e.which)) && e.which != 8) e.preventDefault();
  }

  return (
    <td
      onKeyDown={handleChange}
      data-target={data?.id}
      className='editable_column'
      ref={columnRef}
      contentEditable>
      {data?.price}
    </td>
  );
}

export default EditableColumn;
