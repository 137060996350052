import { Box, Button, Dialog, Typography } from '@material-ui/core'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'

function EditLocation({ open, setOpen, data, refresh }) {
	const [state, setState] = useState({})
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		setState({
			place: data.place,
			latitude: data.latitude,
			longitude: data.longitude,
		})
	}, [data])

	function handleSubmit(e) {
		e.preventDefault()
		const { place, latitude, longitude, ...rest } = data
		let postData = {
			...state,
			...rest,
		}
		delete postData.updatedAt
		delete postData.cretedAt
		http
			.put(`/disease/update`, postData)
			.then((res) => {
				enqueueSnackbar('Hotspot Location Updated', { variant: 'success' })
				setOpen(false)
				refresh()
			})
			.catch((err) => {
				err && enqueueSnackbar('Something went wrong')
			})
	}

	const handleChange = (e) => {
		setState((prev) => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}

	return (
		<Dialog open={open} maxWidth='sm' fullWidth>
			<form onSubmit={handleSubmit}>
				<Box p={2}>
					<Typography gutterBottom variant='subtitle1'>
						Edit Topic
					</Typography>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							value={state.place}
							label='Place'
							name='place'
						/>
					</Box>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							value={state.latitude}
							label='Latitude'
							name='latitude'
						/>
					</Box>
					<Box mt={2}>
						<CustomTextField
							onChange={handleChange}
							required
							value={state.longitude}
							label='Longitude'
							name='longitude'
						/>
					</Box>
					<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
						<Button
							onClick={() => setOpen(false)}
							color='primary'
							variant='outlined'
						>
							Cancel
						</Button>
						<Button color='primary' variant='contained' type='submit'>
							Submit
						</Button>
					</Box>
				</Box>
			</form>
		</Dialog>
	)
}

export default EditLocation
