import {Box, Button, Typography} from '@material-ui/core'
import KeyboardBackspace from '@material-ui/icons/KeyboardBackspace'
import MaterialTable from 'material-table'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {Link} from 'react-router-dom'
import {http} from '../../api/http'

export default function AddFarmerToGroup() {
	const [data, setData] = useState([])
	const history = useHistory()
	const [loading, setLoading] = useState(false)
	const {enqueueSnackbar} = useSnackbar()
	const params = useParams()

	useEffect(() => {
		getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/users?role=User`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const addFarmersToGroup = async (farmerIds) => {
		try {
			await http.put(`/group/${params?.id}/add-members`, farmerIds)
			enqueueSnackbar('Members Updated', {variant: 'success'})
			history.push(`/farmer-groups/${params?.id}`)
		} catch (error) {
			enqueueSnackbar('Something went wrong')
		}
	}

	return (
		<div>
			<div className='custom_table'>
				<Box display='flex' alignItems='center' gridGap={10} mb={2}>
					<Link
						color='textPrimary'
						onClick={() => history.goBack()}
						style={{cursor: 'pointer', display: 'inline-block'}}
					>
						<KeyboardBackspace fontSize='medium' style={{marginTop: '5px'}} />
					</Link>
					<Typography variant='h5'>Add Members to Group</Typography>
				</Box>
				<MaterialTable
					columns={[
						{title: 'Farmer Name', field: 'name'},
						{title: 'Mobile', field: 'mobile'},
						{
							title: 'Address',
							render: (rowData) => rowData.address?.split(',').slice(-3)[0],
						},
					]}
					data={data}
					isLoading={loading}
					title='Farmers'
					components={{
						Action: (props) => {
							if (props.action.icon === 'add') {
								return (
									<Button
										variant='contained'
										color='primary'
										onClick={() => {
											addFarmersToGroup(props.data?.map((item) => item.id))
										}}
									>
										Add to Group
									</Button>
								)
							}
						},
					}}
					actions={[
						{
							icon: 'add',
						},
					]}
					options={{
						actionsColumnIndex: -1,
						selection: true,
					}}
				/>
				{/* {open && (
					<Dialog open={open} maxWidth='sm' fullWidth>
						<form onSubmit={handleNewgroup}>
							<Box p={2}>
								<Typography gutterBottom variant='subtitle1'>
									Add Farmer To The Group
								</Typography>
								<Box mt={2}>
									<CustomTextField
										onChange={(e) => setGroupName(e.target.value)}
										required
										label='Group Name'
										name='name'
										value={groupName}
									/>
								</Box>
								<Box
									display='flex'
									gridGap={10}
									justifyContent='flex-end'
									mt={3}
								>
									<Button
										onClick={() => setOpen(false)}
										color='primary'
										variant='outlined'
									>
										Cancel
									</Button>
									<Button color='primary' variant='contained' type='submit'>
										Submit
									</Button>
								</Box>
							</Box>
						</form>
					</Dialog>
				)} */}
			</div>
		</div>
	)
}
