export const TOPICS = [
	{name: 'Current Affair'},
	{name: 'World Affairs'},
	{name: 'Country Affair'},
	{name: 'Advices'},
	{name: 'Warnings'},
]

export const DOCUMENTS = [
	'LOR',
	'SOP',
	'10th Class',
	'10+2',
	'UG',
	'Transcripts',
	'Passport',
]
export const roles = ['TechnicalExecutive', 'Expert', 'QC Expert', 'Admin']

export const AWS_URL = 'https://jss-vider.s3.amazonaws.com'

export const labelColor = (status) => {
	switch (status) {
		case 'Accepted':
			return '#0093E9'
		case 'Follow Up':
			return '#E8AA2E'
		case 'QC Approved':
			return '#80D0C7'
		case 'QC Rejected':
			return '#A80C00'
		case 'Closed':
			return 'green'
		case 'Prescribed':
			return 'purple'
	}
}
const chipStyles = {
	border: 0,
	color: 'white',
	paddingTop: '4px',
}

export const EXPERT_CHIP = {
	...chipStyles,
	background: '#0093E9',
}
export const QC_EXPERT_CHIP = {
	...chipStyles,
	background: '#E8AA2E',
}
export const ADMIN_CHIP = {
	...chipStyles,
	background: '#A80C00',
}
export const FV_CHIP = {
	...chipStyles,
	background: '#80D0C7',
}
export const USER_CHIP = {
	...chipStyles,
	background: 'purple',
}
