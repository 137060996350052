import {
  Typography,
  Button,
  CircularProgress,
  Box,
  Grid,
} from "@material-ui/core";
import Post from "./Post";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { http } from "../../api/http";
import NoResults from "../../shared/components/NoResults";

function Feed() {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const [wasLastList, setWasLastList] = useState(false);
  const listInnerRef = useRef();

  const getData = async (currentOffset = 0) => {
    setLoading(true);
    try {
      const response = await http.get(
        `/feed?limit=${limit}&offset=${currentOffset}`
      );
      if (!response?.data?.length) {
        setWasLastList(true);
      } else {
        if (currentOffset === 0) {
          setPosts(response?.data);
        } else {
          setPosts((prevPosts) => [...prevPosts, ...response?.data]);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!wasLastList) {
      getData(offset);
    }
  }, [offset, wasLastList]);

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 50) {
        if (!wasLastList) {
          setOffset((prevOffset) => prevOffset + limit);
        }
      }
    }
  };

  return (
    <Box>
      <Box alignItems="center" display="flex" justifyContent="space-between">
        <Typography variant="h6">Posts</Typography>
        <Link to="/feed/add">
          <Button variant="contained" color="primary">
            Add Post
          </Button>
        </Link>
      </Box>
      <Box
        ref={listInnerRef}
        onScroll={onScroll}
        style={{
          maxHeight: `79vh`,
          overflowY: "auto",
          width: "max-content",
          // "::-webkit-scrollbar": {
          //   display: "none",
          //   width: 0,
          // },
          // "-ms-overflow-style": "none",
          // "scrollbar-width": "none",
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {posts.length > 0 ? (
              posts.map((item, index) => (
                <Post refresh={() => getData(1)} data={item} key={index} />
              ))
            ) : (
              <Box maxWidth={400}>
                <NoResults customMessage="No Posts" />
              </Box>
            )}
            {loading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Feed;
