import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import React from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import logo from "../assets/images/logo.png";
import matrixLogo from "../assets/images/matrix_logo.png";
import { menu } from "./menu";
import Routes from "./Routes";
import SettingsIcon from "@material-ui/icons/Settings";
import Fade from "@material-ui/core/Fade";

import GroupIcon from "@material-ui/icons/Group";
import CategoryIcon from "@material-ui/icons/Category";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import SpaIcon from "@material-ui/icons/Spa";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import GroupWorkIcon from "@material-ui/icons/GroupWork";
import BugReportIcon from "@material-ui/icons/BugReport";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxShadow: "0px 0px 15px rgb(0 0 0 / 20%)",
    background: theme.palette.primary.main,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  menu: {
    width: drawerWidth,
  },
}));

function Layout() {
  const classes = useStyles();
  const location = useLocation();
  const { pathname, search } = location;

  const fullPath = `${pathname}${search}`;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout() {
    let confirm = window.confirm("Are you sure you want to logout?");
    if (!confirm) return;
    localStorage.clear();
    window.location.href = "/";
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar color="initial" position="fixed" className={classes.appBar}>
        <Toolbar style={{ justifyContent: "space-between" }}>
          <Typography variant="h6" noWrap>
            <Box display="flex" gridGap={20} alignItems="center">
              <img width={50} src={logo} alt="" />
              <img width={120} src={matrixLogo} alt="" />
            </Box>
          </Typography>
          <Box>
            <IconButton onClick={handleClick}>
              <SettingsIcon style={{ color: "black" }} />
            </IconButton>
            <Button onClick={handleLogout}>Logout</Button>
          </Box>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            TransitionComponent={Fade}
            classes={{
              paper: classes.menu,
            }}
          >
            <CustomMenuItem
              to="/users"
              title="Users"
              onClick={handleClose}
              icon={<GroupIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/topics"
              title="School Topics"
              onClick={handleClose}
              icon={<DoubleArrowIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/categories"
              title="Feed Categories"
              onClick={handleClose}
              icon={<CategoryIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/tags"
              title="Tags"
              onClick={handleClose}
              icon={<LocalOfferIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/products"
              title="Products"
              onClick={handleClose}
              icon={<SpaIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/medicine"
              title="Medicine"
              onClick={handleClose}
              icon={<LocalHospitalIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/medicine-composition"
              title="Medicine Composition"
              onClick={handleClose}
              icon={<GroupWorkIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/symptoms"
              title="Symptoms"
              onClick={handleClose}
              icon={<BugReportIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/farmer-groups"
              title="Farmer Groups"
              onClick={handleClose}
              icon={<GroupAddIcon fontSize="small" />}
            />
            <CustomMenuItem
              to="/hotspot-locations"
              title="Hotspot Locations"
              onClick={handleClose}
              icon={<LocationOnIcon fontSize="small" />}
            />
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List>
            {menu.map((item) => (
              <Link
                to={`${item.path}`}
                style={{
                  color: "white",
                }}
              >
                <ListItem
                  selected={fullPath.startsWith(item.path.split("?")[0])}
                  button
                  key={`${item.path}`}
                >
                  <ListItemIcon>
                    <img width={16} src={item.icon} alt="" />
                  </ListItemIcon>
                  <ListItemText primary={`${item.title}`} />
                </ListItem>
              </Link>
            ))}
          </List>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        <Routes />
      </main>
    </div>
  );
}

export default withRouter(Layout);

const CustomMenuItem = ({ to, title, onClick, icon }) => {
  return (
    <Link style={{ color: "initial" }} to={to}>
      <MenuItem onClick={onClick}>
        <Box display="flex" gridGap={20}>
          <ListItemIcon>{icon}</ListItemIcon>
          <Typography variant="inherit">{title}</Typography>
        </Box>
      </MenuItem>
    </Link>
  );
};
