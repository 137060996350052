import { createTheme } from '@material-ui/core/styles'

const FONT_FAMILY = 'quasimoda, sans-serif'

let theme = createTheme({
	palette: {
		primary: {
			main: '#BF344E',
			light: 'rgba(191, 52, 78, 0.1)',
		},
		secondary: {
			main: '#1976d2',
			light: 'rgba(25, 118, 210, 0.1)',
		},
	},
	overrides: {
		MuiMenuItem: {
			root: {
				fontSize: 14,
				fontFamily: FONT_FAMILY,
			},
		},
		MuiListItemIcon: {
			root: {
				minWidth: 30,
			},
		},
		MuiPaper: {
			elevation2: {
				boxShadow: '0px 0px 15px rgba(0,0,0,0.1)',
			},
			elevation4: {
				boxShadow: '0px 0px 15px rgba(0,0,0,0.2)',
			},
			elevation1: {
				boxShadow: '0px 15px 15px #22222214',
			},
		},
		MuiCard: {
			root: {
				borderRadius: '5px',
			},
		},
		MuiInputLabel: {
			root: {
				fontFamily: FONT_FAMILY,
				fontSize: 14,
			},
		},
		MuiButton: {
			root: {
				textTransform: 'capitalize',
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			contained: {
				boxShadow: '0px 0px 10px rgba(0,0,0,0.1)',
				padding: '8px 20px',
			},
			outlined: {
				padding: '8px 20px',
			},
		},
		MuiTypography: {
			root: {
				fontFamily: FONT_FAMILY,
			},
			h1: {
				fontSize: 40,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
				lineHeight: '50px',
			},

			h2: {
				fontSize: 36,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			h3: {
				fontSize: 30,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			h4: {
				fontSize: 24,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			h5: {
				fontSize: 20,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},

			h6: {
				fontSize: 18,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			body1: {
				fontSize: 16,
				fontFamily: FONT_FAMILY,
				lineHeight: '30px',
			},
			body2: {
				fontSize: 14,
				fontFamily: FONT_FAMILY,
			},
			subtitle1: {
				fontSize: 18,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			subtitle2: {
				fontSize: 16,
				fontFamily: FONT_FAMILY,
				fontWeight: 700,
			},
			caption: {
				fontSize: 12,
				fontFamily: FONT_FAMILY,
			},
		},
	},
})

export default theme
