import {
	Box,
	Button,
	Chip,
	Dialog,
	DialogTitle,
	TextField,
} from '@material-ui/core'
import {Autocomplete} from '@material-ui/lab'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import {http} from '../../api/http'

export default function EditFarmer({open, setOpen, data, refresh}) {
	const [state, setState] = useState(data)
	const {enqueueSnackbar} = useSnackbar()

	const handleSubmit = async (e) => {
		e.preventDefault()

		try {
			const res = await http.patch(`/users/${state?.user_id}`, {
				name: state?.user_name,
				role: state?.user_role,
				address: state?.user_address,
				mobile: state?.user_mobile,
			})
			res && enqueueSnackbar('User Updated', {variant: 'success'})
			refresh()
			setOpen(false)
		} catch (error) {
			console.log(error.response)
			enqueueSnackbar('Error Updating User')
		}
	}

	useEffect(() => {
		setState({...data, user_role: data?.user_role.split(',')})
	}, [data])

	const fixedOptions = ['User']
	return (
		<div>
			<Dialog open={open} maxWidth='sm' fullWidth>
				<DialogTitle>Edit Farmer</DialogTitle>
				<form onSubmit={handleSubmit}>
					<Box p={2}>
						<Box display='flex' gridGap={20} flexDirection={'column'}>
							{/* <Autocomplete
								id='tags-outlined'
								options={volunteers?.map((item) => ({
									name: item.name,
									id: item.id,
								}))}
								onChange={(e, newValue) => {
									console.log(e.target)
									setVolunteerId(newValue?.id)
								}}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								size='small'
								renderInput={(params) => (
									<TextField {...params} variant='outlined' label='Assign' />
								)}
							/> */}
							<TextField
								label='Farmer Name'
								size='small'
								variant='outlined'
								fullWidth
								value={state?.user_name || ''}
								onChange={(e) =>
									setState({
										...state,
										user_name: e.target.value,
									})
								}
							/>
							<TextField
								label='Location'
								size='small'
								variant='outlined'
								fullWidth
								value={state?.user_address || ''}
								onChange={(e) =>
									setState({
										...state,
										user_address: e.target.value,
									})
								}
							/>
							<Box>
								<Autocomplete
									multiple
									options={['TechnicalExecutive', 'Expert', 'QC Expert']}
									getOptionLabel={(option) => option}
									size='small'
									value={state?.user_role || []}
									getOptionSelected={(option, value) => option === value}
									renderTags={(tagValue, getTagProps) =>
										tagValue.map((option, index) => (
											<Chip
												label={option}
												{...getTagProps({index})}
												disabled={fixedOptions.indexOf(option) !== -1}
											/>
										))
									}
									onChange={(e, newValue) => {
										setState((prev) => ({
											...prev,
											user_role: newValue,
										}))
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											variant='outlined'
											label='Select Role(s)'
										/>
									)}
								/>
							</Box>
						</Box>
						<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
							<Button
								onClick={() => {
									setOpen(false)
								}}
								color='primary'
								variant='outlined'
							>
								Cancel
							</Button>
							<Button color='primary' variant='contained' type='submit'>
								Submit
							</Button>
						</Box>
					</Box>
				</form>
			</Dialog>
		</div>
	)
}
