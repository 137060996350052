import {useEffect} from 'react'
import {Route} from 'react-router-dom'
import {http} from '../../api/http'

const ProtectedRoute = ({component: Component, ...rest}) => {
	useEffect(() => {
		if (localStorage.getItem('access_token')) {
			http
				.get('/users/current-user/profile')
				.then((res) => {})
				.catch((err) => {
					console.log(err)
					window.location.href = '/login'
				})
			return
		}
		if (!localStorage.getItem('access_token')) {
			window.location.href = '/login'
		}
	})
	return <Route {...rest} component={Component} />
}

export default ProtectedRoute
