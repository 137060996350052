import React, {useEffect, useState} from 'react'
import MaterialTable from 'material-table'
import {Box, Button, CircularProgress, Typography} from '@material-ui/core'
import {http} from '../../api/http'
import {useSnackbar} from 'notistack'
import AddMember from './AddMember'
import {Link, useHistory, useParams} from 'react-router-dom'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

function Members() {
	const [open, setOpen] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const {enqueueSnackbar} = useSnackbar()
	const history = useHistory()

	const params = useParams()

	useEffect(() => {
		getData()
		//eslint-disable-next-line
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/group/${params.id}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	function handleDelete(farmerIds) {
		let confirm = window.confirm('Are you sure you want to delete this?')
		if (!confirm) return
		http
			.put(`/group/${params?.id}/remove-members`, farmerIds)
			.then((res) => {
				enqueueSnackbar('Members Deleted', {variant: 'success'})
				getData()
			})
			.catch((err) => {
				enqueueSnackbar('Something went wrong')
			})
	}
	console.log(data)
	if (loading) return <CircularProgress />

	return (
		<div className='custom_table'>
			<Box display='flex' alignItems='center' gridGap={10} mb={2}>
				<Link
					color='textPrimary'
					onClick={() => history.goBack()}
					style={{cursor: 'pointer', display: 'inline-block'}}
				>
					<KeyboardBackspaceIcon fontSize='medium' style={{marginTop: '5px'}} />
				</Link>
				<Typography variant='h5'>Back</Typography>
			</Box>
			<Box mb={2} textAlign='right'>
				<Button
					onClick={() =>
						history.push(`/farmer-groups/${params.id}/add-farmers`)
					}
					variant='contained'
					color='primary'
				>
					Add Members
				</Button>
			</Box>
			<MaterialTable
				columns={[
					{title: 'Farmer Name', field: 'name'},
					{title: 'Mobile', field: 'mobile'},
					{title: 'Address', field: 'address'},
					{
						title: 'City',
						render: (rowData) => rowData.address?.split(',').slice(-3)[0],
					},
				]}
				data={data}
				isLoading={loading}
				title='Group'
				actions={[
					{
						icon: 'delete',
					},
				]}
				components={{
					Action: (props) => {
						if (props.action.icon === 'delete') {
							return (
								<Button
									variant='contained'
									color='primary'
									onClick={() => {
										handleDelete(props.data?.map((item) => item.id))
									}}
								>
									Remove Members
								</Button>
							)
						}
					},
				}}
				options={{
					actionsColumnIndex: -1,
					selection: true,
				}}
			/>
			<AddMember refresh={getData} open={open} setOpen={setOpen} />
		</div>
	)
}

export default Members
