import { Box, Button, Typography } from '@material-ui/core'
import MaterialTable from 'material-table'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { http } from '../../api/http'

const Notifications = () => {
	const [loading, setLoading] = useState(false)
	const [data, setData] = useState()

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		try {
			setLoading(true)
			const res = await http.get(`/admin-notifications`)
			setData(res.data)
			setLoading(false)
		} catch (error) {
			console.log(error)
		}
	}
	return (
		<div>
			<Box display='flex' justifyContent='space-between' mb={2}>
				<Typography variant='h6' component='h1'>
					Notifications
				</Typography>
				<Link to='/new-notification'>
					<Button variant='contained' color='primary'>
						Send Notification
					</Button>
				</Link>
			</Box>
			<Box>
				<MaterialTable
					data={data}
					columns={[
						{ title: 'Title', field: 'notification.title' },
						{ title: 'Message', field: 'notification.body' },
						// {
						// 	title: 'Groups',
						// 	field: 'groupIds',
						// 	render: (r, data) => '',
						// },
						{
							title: 'Date',
							field: 'createdAt',
							render: (r, data) =>
								moment(r.createdAt).format('MMMM Do YYYY, h:mm a'),
						},
					]}
					isLoading={loading}
					title='Medicine'
					actions={[]}
					options={{
						actionsColumnIndex: -1,
					}}
				/>
			</Box>
		</div>
	)
}

export default Notifications
