import { Dialog } from '@material-ui/core'

const PhotoDialog = ({ imageUrl, open, setOpen }) => {
	return (
		<Dialog open={open} onClose={() => setOpen(false)}>
			<img src={imageUrl} style={{ objectFit: 'contain' }} />
		</Dialog>
	)
}

export default PhotoDialog
