import { Typography, Box, Divider, TextField, Button } from "@material-ui/core";
import { Link, NavLink, Redirect, Route, useHistory } from "react-router-dom";
import Panel from "./Panel";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  tabsBar: {
    display: "flex",
    gap: "40px",
  },
  tab: {
    textDecoration: "none",
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.text.secondary,
  },
  activeTab: {
    color: theme.palette.primary.main,
    "&::after": {
      content: '""',
      display: "block",
      padding: "2px 3px",
      height: "5px",
      width: "6ch",
      background: theme.palette.primary.main,
      borderRadius: "3px",
      marginTop: "5px",
    },
  },
}));

const ActiveTickets = () => {
  const classes = useStyles();
  const history = useHistory();
  const query = new URLSearchParams(history.location.search);

  function getClass(status) {
    return query.get("status") === status
      ? classes.tab + " " + classes.activeTab
      : classes.tab;
  }

  function generateQuery(status) {
    let result = `/tickets?status=${status}`;
    if (query.get("farmerId")) {
      result += `&farmerId=${query.get("farmerId")}`;
    }
    return result;
  }

  return (
    <>
      <Typography variant="h6" component="h1">
        {query.get("farmerId") ? "Farmer's Tickets" : "Active Tickets"}
      </Typography>
      <Box marginTop={2}>
        <Box>
          <Box className={classes.tabsBar}>
            <Link className={getClass("active")} to={generateQuery("active")}>
              <span>{`Active `}</span>
            </Link>
            <Link className={getClass("follow")} to={generateQuery("follow")}>
              <span>{`Follow Up `}</span>
            </Link>
            <Link className={getClass("closed")} to={generateQuery("closed")}>
              <span>{`Completed `}</span>
            </Link>
          </Box>
        </Box>
        <Divider />
        <Route path="/tickets" component={Panel} />
      </Box>
    </>
  );
};

export default ActiveTickets;
