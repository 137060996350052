import {
	Box,
	Button,
	Typography,
	CircularProgress,
	Grid,
} from '@material-ui/core'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { http } from '../../api/http'
import NoResults from '../../shared/components/NoResults'

function View() {
	const router = useHistory()
	const history = useRouteMatch()
	const [value, setValue] = useState(0)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)

	const handleChange = (event, newValue) => {
		setValue(newValue)
	}

	useEffect(() => {
		getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get(`/aqua-school-topics/${history.params.id}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}

	if (loading) return <CircularProgress />

	return (
		<Box>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography variant='h6'>{data?.aquaSchool?.name}</Typography>
				<Box display='flex' gridGap={10}>
					<Button
						onClick={() => router.goBack()}
						color='primary'
						variant='outlined'
					>
						Back
					</Button>
					<Button
						onClick={() => router.push(`/school/${data?.id}/edit`)}
						variant='contained'
						color='primary'
					>
						Edit
					</Button>
					<Button
						onClick={() => {
							const res = window.confirm('Confirm Delete Item')
							if (res) {
								http
									.delete(`/aqua-school-topics/${data?.id}`)
									.catch(() => console.error('Error deleting Item'))
								router.goBack()
							}
						}}
						variant='contained'
						color='primary'
					>
						Delete
					</Button>
				</Box>
			</Box>
			<Box maxWidth={800} mt={2} width='100%'>
				<img
					style={{
						width: 400,
						height: 200,
						objectFit: 'contain',
						borderRadius: 10,
					}}
					src={data?.coverPhotoUrl}
					alt=''
				/>
				<Typography variant='body1' gutterBottom>
					{data?.name}
				</Typography>
				<Typography color='textSecondary' variant='body2' gutterBottom>
					{data?.description}
				</Typography>
				<Box mt={2}>
					<Tabs
						value={value}
						indicatorColor='primary'
						onChange={handleChange}
						aria-label='simple tabs example'
					>
						<Tab label='Resources' {...a11yProps(0)} />
						<Tab label='Videos' {...a11yProps(1)} />
						<Tab label='Photos' {...a11yProps(2)} />
						<Tab label='Products to use' {...a11yProps(3)} />
					</Tabs>
					<TabPanel value={value} index={0}>
						<div
							className='quill-view'
							dangerouslySetInnerHTML={{
								__html: data?.resources,
							}}
						></div>
					</TabPanel>
					<TabPanel value={value} index={1}>
						<Grid container spacing={2}>
							{data?.videos?.length > 0 ? (
								data?.videos?.map((item) => (
									<Grid item xs={6}>
										<iframe
											style={{ width: '100%', height: '220px' }}
											src={item}
											frameborder='0'
										></iframe>
									</Grid>
								))
							) : (
								<NoResults customMessage='No Results' />
							)}
						</Grid>
					</TabPanel>
					<TabPanel value={value} index={2}>
						<Grid container spacing={2}>
							{data?.photosUrl?.length > 0 ? (
								data?.photosUrl?.map((item) => (
									<Grid item xs={6}>
										<img
											src={item}
											style={{
												width: '100%',
												height: '220px',
												objectFit: 'contain',
											}}
										/>
									</Grid>
								))
							) : (
								<NoResults customMessage='No Results' />
							)}
						</Grid>
					</TabPanel>
					<TabPanel value={value} index={3}>
						<Grid container spacing={2}>
							{data?.productsList?.length > 0 ? (
								data?.productsList?.map((item, index) => (
									<Grid item xs={6}>
										<Box
											className='cursor_pointer'
											width='100%'
											overflow='hidden'
											boxShadow='0px 5px 15px #0000000F'
											borderRadius={10}
										>
											<Box p={1}>
												<Typography variant='subtitle2' gutterBottom>
													{item?.name}
												</Typography>
											</Box>
											<img
												src={item?.photoUrl}
												style={{
													width: '100%',
													height: 200,
													objectFit: 'cover',
												}}
												alt=''
											/>
											<Box px={2} py={1}>
												<Typography variant='body2' gutterBottom>
													&#8377; {item?.price} /-
												</Typography>
											</Box>
										</Box>
									</Grid>
								))
							) : (
								<NoResults customMessage='No Results' />
							)}
						</Grid>
					</TabPanel>
				</Box>
			</Box>
		</Box>
	)
}

function TabPanel(props) {
	const { children, value, index, ...other } = props

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	)
}

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

export default View
