import {
	Box,
	Button,
	CircularProgress,
	IconButton,
	MenuItem,
	TextField,
	Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import {useSnackbar} from 'notistack'
import React, {useEffect, useState} from 'react'
import {useMemo} from 'react'
import {useRef} from 'react'
import ReactQuill from 'react-quill'
import {useHistory} from 'react-router-dom'
import {http} from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'
import CustomUpload from '../../shared/components/CustomUpload'

function AddPost() {
	const router = useHistory()
	const mainRef = useRef(null)
	const {enqueueSnackbar} = useSnackbar()
	const [state, setState] = useState({
		aquaSchool: '',
		name: '',
		description: '',
		coverPhoto: '',
		coverPhotoUrl: '',
		photos: [],
		photosUrl: [],
		videos: [],
		products: [],
	})
	const [data, setData] = useState({
		categories: [],
		products: [],
	})
	const [videoLink, setVideoLink] = useState('')
	const [loading, setLoading] = useState(false)
	const modules = useMemo(
		() => ({
			toolbar: {
				clipboard: {
					matchVisual: false,
				},
				container: [
					[{header: [1, 2, false]}],
					['bold', 'italic', 'underline'],
					[{list: 'ordered'}, {list: 'bullet'}],
					['image'],
				],
				handlers: {
					image: quillImageHandler,
				},
			},
		}),
		//eslint-disable-next-line
		[]
	)

	useEffect(() => {
		getData()
		//eslint-disable-next-line
	}, [])

	async function getData() {
		try {
			setLoading(true)
			let categories = await http.get('/aqua-school')
			let products = await http.get('/product')
			setData({
				categories: categories.data,
				products: products.data.data,
			})
			setLoading(false)
		} catch (err) {
			console.error(err)
			setLoading(false)
		}
	}

	function handleVideoLinks(e) {
		setVideoLink(e.target.value)
	}

	function addVideoLink() {
		if (!videoLink) {
			enqueueSnackbar('Video link should not be empty', {variant: 'error'})
			return
		}
		setState({
			...state,
			videos: [...state.videos, videoLink],
		})
		setVideoLink('')
	}

	function deleteVideoLink(idx) {
		let filtered = state.videos?.filter((item, index) => index !== idx)
		setState({
			...state,
			videos: filtered,
		})
	}

	function addImage(v) {
		console.log(v)
		setState({
			...state,
			photos: [...state.photos, v.key],
			photosUrl: [...state.photosUrl, v.Location],
		})
	}

	function deleteImage(idx) {
		let filtered = state.photos?.filter((item, index) => index !== idx)
		let filteredPhotoUrl = state.photosUrl?.filter(
			(item, index) => index !== idx
		)
		setState({
			...state,
			photos: filtered,
			photosUrl: filteredPhotoUrl,
		})
	}

	function handleSubmit(e) {
		e.preventDefault()

		if (!state.name || !state.coverPhoto || !state.aquaSchool) {
			enqueueSnackbar('Enter required fields', {variant: 'error'})
			return
		}
		let products = []
		state.products.forEach((product) => {
			let exist = data?.products?.find((item) => item?.name == product)
			if (exist) {
				products.push(exist?.id)
			}
		})
		http
			.post('/aqua-school-topics', {
				...state,
				products,
				resources: mainRef.current.state.value.replaceAll('<p><br></p>', ''),
			})
			.then((res) => {
				enqueueSnackbar('Aqua School Post created', {variant: 'success'})
				router.push('/school')
			})
			.catch((err) => {
				enqueueSnackbar('something went wrong', {variant: 'error'})
				console.log(err)
			})
	}

	function getBase64(img, callback) {
		const reader = new FileReader()
		reader.addEventListener('load', () => callback(reader.result))
		reader.readAsDataURL(img)
	}

	function quillImageHandler(e) {
		const cursorPosition = mainRef.current.getEditor().getSelection().index
		const file = document.createElement('input')
		file.type = 'file'
		file.click()

		file.addEventListener('change', (e) => {
			let base64Img

			getBase64(e.target.files[0], (img) => {
				base64Img = img
			})
			const formData = new FormData()
			formData.append('file', e.target.files[0])
			formData.append('type', 'resource')
			http
				.post('/upload', formData)
				.then((res) => {
					mainRef.current
						.getEditor()
						.insertEmbed(cursorPosition, 'image', res.Location)
					mainRef.current.getEditor().setSelection(cursorPosition + 1)
				})
				.catch((err) => {
					this.quill.insertEmbed(cursorPosition, 'image', base64Img)
					this.quill.setSelection(cursorPosition + 1)
				})
		})
	}

	if (loading) return <CircularProgress />

	return (
		<Box maxWidth={600} width='100%' margin='auto'>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography gutterBottom variant='subtitle2'>
					Add a Post
				</Typography>
				<Button onClick={() => router.goBack()} color='primary'>
					Back
				</Button>
			</Box>
			<form onSubmit={handleSubmit}>
				<Box mt={2}>
					<TextField
						select
						fullWidth
						variant='outlined'
						onChange={(e) => setState({...state, aquaSchool: e.target.value})}
						size='small'
						label='Category'
					>
						{data?.categories?.map((item) => (
							<MenuItem value={item?.id}>{item?.name}</MenuItem>
						))}
					</TextField>
				</Box>
				<Box mt={3}>
					<CustomTextField
						value={state.name}
						onChange={(e) => setState({...state, name: e.target.value})}
						label='Name'
					/>
				</Box>
				<Box mt={3}>
					<CustomUpload
						onChange={(v) =>
							setState((prev) => ({
								...prev,
								coverPhoto: v.key,
								coverPhotoUrl: v.Location,
							}))
						}
						label='Upload Cover Image'
						name='image'
					/>
				</Box>
				<Box mt={5} display='flex' flexWrap='wrap' gridGap={10}>
					{state.coverPhotoUrl && (
						<Box position='relative'>
							<img
								width='100%'
								height='220px'
								style={{objectFit: 'contain'}}
								src={state.coverPhotoUrl}
								alt={state.description}
							/>
						</Box>
					)}
				</Box>

				<Box mt={3}>
					<CustomTextField
						value={state.description}
						onChange={(e) =>
							setState((prev) => ({...prev, description: e.target.value}))
						}
						label='Description'
						multiline
						rows={5}
					/>
				</Box>
				<Box mt={3}>
					<Typography gutterBottom variant='subtitle2'>
						Resources
					</Typography>
					<ReactQuill ref={mainRef} modules={modules} />
				</Box>
				<Box mt={4}>
					<CustomUpload onChange={addImage} label='Add Images' name='img' />
					<Box mt={2}>
						{state?.photosUrl?.length ? (
							<>
								<Typography variant='caption' color='textSecondary'>
									Added Images:
								</Typography>
								<Box mt={5} display='flex' flexWrap='wrap' gridGap={10}>
									{state?.photosUrl?.map((item, index) => (
										<Box position='relative'>
											<img
												width='100px'
												height='100px'
												style={{objectFit: 'contain'}}
												src={item}
												alt=''
											/>
											<IconButton
												size='small'
												style={{
													color: 'red',
													position: 'absolute',
													top: '-20px',
													right: '-10px',
												}}
												onClick={() => deleteImage(index)}
											>
												<DeleteIcon />
											</IconButton>
										</Box>
									))}
								</Box>
							</>
						) : null}
					</Box>
				</Box>
				<Box mt={4}>
					<Box display='flex' gridGap={2}>
						<CustomTextField
							value={videoLink}
							onChange={handleVideoLinks}
							label='Add Video Links'
						/>
						<Button onClick={addVideoLink} color='primary'>
							Add
						</Button>
					</Box>
					<Box mt={2}>
						{state?.videos?.length ? (
							<Typography variant='caption' color='textSecondary'>
								Added Links:
							</Typography>
						) : null}
						{state?.videos?.map((item, index) => (
							<Box display='flex' alignItems='center' gridGap={2}>
								<Box flex={1}>
									<Typography variant='body2'>{item}</Typography>
								</Box>
								<IconButton onClick={() => deleteVideoLink(index)}>
									<DeleteIcon />
								</IconButton>
							</Box>
						))}
					</Box>
				</Box>
				<Box mt={3}>
					<Autocomplete
						size='small'
						multiple
						options={data?.products?.map((item) => item?.name) || []}
						getOptionLabel={(option) => option}
						onChange={(e, newValue) => setState({...state, products: newValue})}
						filterSelectedOptions
						renderInput={(params) => (
							<TextField
								{...params}
								variant='outlined'
								label='Select Products'
							/>
						)}
					/>
				</Box>
				<Box mt={3} textAlign='right'>
					<Button type='submit' color='primary' variant='contained'>
						Submit Post
					</Button>
				</Box>
			</form>
		</Box>
	)
}

export default AddPost
