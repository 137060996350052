import { Box, Button, CircularProgress, Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { http } from '../../api/http'
import AddProduct from './AddProduct'
import EditProduct from './EditProduct'
import CustomPagination from './Pagination'
import Product from './Product'

function Products() {
	const [open, setOpen] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [offset, setOffset] = useState(0)
	const [count, setCount] = useState(null)
	const [page, setPage] = useState(1)
	const [selectedData, setSelectedData] = useState({})
	const [editOpen, setEditOpen] = useState(false)

	useEffect(() => {
		getData()
		//eslint-disable-next-line
	}, [offset])

	function getData() {
		setLoading(true)
		http
			.get('/product', {
				params: {
					offset: offset,
					limit: 8,
				},
			})
			.then((res) => {
				setCount(res.data.count)
				setData(res.data.data)
				setTimeout(() => {
					setLoading(false)
				}, 400)
			})
			.catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}

	return (
		<Box>
			<Box display='flex' justifyContent='flex-end' gridGap={10}>
				<Box>
					<Button
						onClick={() => setOpen(true)}
						variant='contained'
						color='primary'
					>
						Add Product
					</Button>
				</Box>
			</Box>
			<Box mt={5}>
				{loading ? (
					<Box
						display='flex'
						alignItems='center'
						justifyContent='center'
						minHeight={500}
					>
						<CircularProgress />
					</Box>
				) : (
					<Grid container spacing={2}>
						{data?.map((item, index) => (
							<Grid item xs={3}>
								<Product
									refresh={getData}
									setSelectedData={setSelectedData}
									setEditOpen={setEditOpen}
									data={item}
									key={index}
								/>
							</Grid>
						))}
					</Grid>
				)}
				{count > 8 && (
					<CustomPagination
						setOffset={setOffset}
						page={page}
						setPage={setPage}
						count={count}
					/>
				)}
			</Box>
			<AddProduct refresh={getData} open={open} setOpen={setOpen} />
			<EditProduct
				open={editOpen}
				setOpen={setEditOpen}
				refresh={getData}
				data={selectedData}
			/>
		</Box>
	)
}

export default Products
