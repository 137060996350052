import React, { useEffect, useState } from 'react'
import MaterialTable from 'material-table'
import { Box, Button, CircularProgress } from '@material-ui/core'
import { http } from '../../api/http'
import { useSnackbar } from 'notistack'
import AddMedicine from './AddComposition'
import EditMedicine from './EditComposition'

function MedicineComposition() {
	const [open, setOpen] = useState(false)
	const [editOpen, setEditOpen] = useState(false)
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [selectedData, setSelectedData] = useState({})
	const { enqueueSnackbar } = useSnackbar()

	useEffect(() => {
		getData()
	}, [])

	function getData() {
		setLoading(true)
		http
			.get('/composition')
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	function handleDelete(e, rowData) {
		let confirm = window.confirm('Are you sure you want to delete this?')
		if (!confirm) return
		http
			.delete(`/composition/${rowData?.id}`)
			.then(() => {
				enqueueSnackbar('Medicine Composition Deleted', { variant: 'success' })
				getData()
			})
			.catch(() => {
				enqueueSnackbar('Something went wrong')
			})
	}

	if (loading) return <CircularProgress />

	return (
		<div className='custom_table'>
			<Box mb={2} textAlign='right'>
				<Button
					onClick={() => setOpen(true)}
					variant='contained'
					color='primary'
				>
					Add Medicine Composition
				</Button>
			</Box>
			<MaterialTable
				columns={[{ title: 'Name', field: 'name' }]}
				data={data}
				isLoading={loading}
				title='Medicine Composition'
				actions={[
					{
						icon: 'edit',
						onClick: (e, rowData) => {
							console.log(rowData)
							setSelectedData(rowData)
							setEditOpen(true)
						},
					},
					{
						icon: 'delete',
						onClick: handleDelete,
					},
				]}
				options={{
					actionsColumnIndex: -1,
				}}
			/>
			<AddMedicine refresh={getData} open={open} setOpen={setOpen} />
			<EditMedicine
				data={selectedData}
				refresh={getData}
				open={editOpen}
				setOpen={setEditOpen}
			/>
		</div>
	)
}

export default MedicineComposition
