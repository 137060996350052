import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Route, useParams, useRouteMatch, useHistory } from "react-router-dom";
import { http } from "../../../api/http";
import { useState } from "react";
import { useEffect } from "react";
import StepperTimeline from "./StepperTimeline";
import ActiveTicketCard from "./ActiveTicketCard";
import NoResults from "../../../shared/components/NoResults";
import ClearIcon from "@material-ui/icons/Clear";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
const Panel = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showCard, setShowCard] = useState({
    status: false,
    id: null,
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [showClearBtn, setShowClearBtn] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { pathname, search } = location;

  const query = new URLSearchParams(history.location.search);

  useEffect(() => {
    setSearchQuery("");
    setShowClearBtn(false);
  }, [search]);

  useEffect(() => {
    setLoading(true);
    getData();
    setShowCard({
      status: false,
      id: null,
    });
  }, [query.get("status"), query.get("farmerId")]);

  const getStatus = () => {
    let status = query.get("status");

    if (status === "active")
      return "Accepted,Prescribed,QC Approved,QC Rejected";

    if (status === "closed") return "Closed";

    if (status === "follow") return "Follow Up";
  };

  const getUrl = () => {
    if (query.get("farmerId")) {
      return `/request-help?status=${getStatus()}&userId=${query.get(
        "farmerId"
      )}`;
    } else {
      return `request-help?status=${getStatus()}`;
    }
  };

  function getData(search) {
    if (search) setShowClearBtn(true);
    const url = search ? `${getUrl()}&ticketId=${search}` : `${getUrl()}`;
    http
      .get(url)
      .then((res) => {
        setTickets(res.data);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    getData(searchQuery);
  };

  function clearSearch() {
    setShowClearBtn(false);
    setSearchQuery("");
    getData();
  }

  if (loading) return <CircularProgress />;

  return (
    <Box display="flex">
      <Box>
        <Box
          style={{
            overflowY: "scroll",
            height: "calc(100vh - 190px)",
            minWidth: 400,
          }}
          pb={2}
          pr={2}
        >
          <Box mt={1}>
            <form
              onSubmit={handleSubmit}
              style={{ display: "flex", gap: "20px" }}
            >
              <TextField
                size="small"
                variant="outlined"
                fullWidth
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search Tickets"
              />
              <Button
                size="medium"
                type="submit"
                variant="contained"
                color="primary"
              >
                Search
              </Button>
            </form>
          </Box>
          {showClearBtn && (
            <Box display="flex" gridGap={20} alignItems="center" m={1} mb={-2}>
              <Typography>{`Search Results for "${searchQuery}"`}</Typography>
              <IconButton onClick={clearSearch}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Box>
          )}
          <Box>
            {tickets.length < 1 ? (
              <NoResults />
            ) : (
              tickets.map((item) => (
                <div onClick={() => setShowCard({ status: true, id: item.id })}>
                  <ActiveTicketCard
                    key={item.id}
                    cardData={item}
                    selectedTicket={showCard.id}
                  />
                </div>
              ))
            )}
          </Box>
        </Box>
      </Box>
      <Box flex="auto" maxWidth={650}>
        {showCard.status && showCard.id && (
          <StepperTimeline ticketId={showCard.id} />
        )}
      </Box>
    </Box>
  );
};

export default Panel;
