// import dashboard from '../assets/images/menu/dashboard.svg'
import farmer from "../assets/images/menu/farmer.png";
import feed from "../assets/images/menu/feed.png";
import price from "../assets/images/menu/price.svg";
import school from "../assets/images/menu/school.png";
import ticket from "../assets/images/menu/ticket.svg";
import scanner from "../assets/images/menu/scanner.svg";
// import fieldVolunteers from '../assets/images/menu/field_volunteers.png'

export const menu = [
  {
    title: "Farmers",
    path: "/farmers",
    icon: farmer,
    children: [],
  },

  // {
  // 	title: 'Dashboard',
  // 	path: '/dashboard',
  // 	icon: dashboard,
  // 	children: [],
  // },
  {
    title: "Farmer Tickets",
    path: "/tickets?status=active",
    icon: ticket,
    children: [],
  },
  {
    title: "Aqua Updates",
    path: "/feed",
    icon: feed,
    children: [],
  },
  {
    title: "Aqua School",
    path: "/school",
    icon: school,
    children: [],
  },
  {
    title: "Market Prices",
    path: "/market-prices",
    icon: price,
    children: [],
  },
  {
    title: "Disease Scanner",
    path: "/disease-scanner",
    icon: scanner,
    children: [],
  },
  // {
  // 	title: 'Field Volunteers',
  // 	path: '/field-volunteers',
  // 	icon: fieldVolunteers,
  // 	children: [],
  // },
  {
    title: "Notifications",
    path: "/notifications",
    icon: farmer,
    children: [],
  },
];
