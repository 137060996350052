import {
	Box,
	Button,
	Chip,
	CircularProgress,
	Typography,
} from '@material-ui/core'
import {useHistory} from 'react-router-dom'
import MaterialTable from 'material-table'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import {http} from '../../api/http'
import AssignVolunteer from './AssignVolunteer'
import {makeStyles} from '@material-ui/core/styles'
import ExportToExcel from '../../shared/components/ExportToExcel'
import ReassignVolunteer from './ReassignVolunteer'
import EditFarmer from './EditFarmer'

const useStyles = makeStyles((theme) => ({
	root: {},
	button: {
		padding: '8px',
		borderRadius: '5px',
		fontSize: '.8rem',
		background: theme.palette.primary.main,
		color: theme.palette.common.white,
		marginLeft: '20px',
	},
}))

function Farmers() {
	const classes = useStyles()
	const history = useHistory()
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [open, setOpen] = useState(false)
	const [openReassign, setOpenReassign] = useState(false)
	const [selectedFarmerIds, setSelectedFarmerIds] = useState([])
	const [farmerIdToReassign, setFarmerIdToReassign] = useState(null)
	const [isVerified, setIsVerified] = useState()

	const [farmerData, setFarmerData] = useState(null)
	const [openFarmerEdit, setOpenFarmerEdit] = useState(false)

	useEffect(() => {
		setLoading(true)
		getData()
	}, [])

	function getData() {
		http
			.get('/kyc/admin')
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	if (loading) return <CircularProgress />
	// console.log('open reassign', openReassign)

	return (
		<div className={classes.root}>
			<Box
				mb={2}
				display='flex'
				justifyContent='space-between'
				alignItems='center'
			>
				<Typography variant='subtitle1'>Farmers</Typography>
			</Box>

			<MaterialTable
				columns={[
					{
						title: 'Registered Date',
						field: 'user_created_at',
						render: (rowData) =>
							moment(rowData.user_created_at).format('MMM Do YYYY'),
					},
					{
						title: 'Requested Date',
						field: 'user_updated_at',
						render: (rowData) =>
							moment(rowData.user_updated_at).format('MMM Do YYYY'),
					},
					{title: 'Name', field: 'user_name'},
					{title: 'Mobile', field: 'user_mobile'},
					{title: 'Address', field: 'user_address'},
					{
						title: 'City',
						render: (rowData) => rowData.user_address?.split(',').slice(-3)[0],
					},

					{
						title: 'KYC Status',
						field: 'kyc_kyc_verified',
						render: (rowData) =>
							rowData.kyc_kyc_verified == null ? (
								<CustomChip label='Not Requested' color='orange' />
							) : rowData.kyc_kyc_verified == 1 ? (
								<CustomChip label='Done' color='green' />
							) : rowData.kyc_volunteer_id ? (
								<CustomChip label='Assigned' color='purple' />
							) : (
								<CustomChip label='Pending' color='red' />
							),
					},
					{title: 'Technical Executive', field: 'kyc_volunteer_name'},
				]}
				components={{
					Action: (props) => {
						if (props.action.icon === 'assign') {
							return (
								<>
									{props.data[0].kyc_volunteer_id ? (
										<Button
											className={classes.button}
											variant='contained'
											color='primary'
											onClick={() => {
												setOpenReassign(true)
												setFarmerIdToReassign(props.data[0].user_id)
												setIsVerified(!!props.data[0].kyc_kyc_verified)
											}}
											disabled={props.data.length > 1}
										>
											Re-Assign Technical Executive
										</Button>
									) : (
										<Button
											className={classes.button}
											variant='contained'
											color='primary'
											onClick={(e) => {
												setSelectedFarmerIds(
													props.data.map((item) => item.user_id)
												)
												setOpen(true)
											}}
											disabled={props.data.some(
												(item) =>
													item.kyc_kyc_verified == 1 ||
													item.kyc_kyc_verified == null ||
													!!item.kyc_volunteer_id
											)}
										>
											Assign Technical Executive
										</Button>
									)}
								</>
							)
						}
						if (props.action.icon === 'view') {
							return (
								<Button
									className={classes.button}
									variant='contained'
									color='primary'
									onClick={(e) => {
										history.push(`/farmers/${props.data[0]?.user_id}`)
									}}
									disabled={
										props.data[0].kyc_kyc_verified === 0 ||
										props.data[0].kyc_kyc_verified === null ||
										props.data.length > 1
									}
								>
									View Profile
								</Button>
							)
						}
						if (props.action.icon === 'export') {
							const labels = [
								{
									title: 'Registered Date',
									key: 'user_created_at',
								},
								{title: 'Name', key: 'user_name'},
								{title: 'Mobile', key: 'user_mobile'},
								{title: 'Address', key: 'user_address'},
								{title: 'City', key: 'city'},
								{title: 'KYC Status', key: 'kyc_kyc_verified'},
								{title: 'Technical Executive', key: 'kyc_volunteer_name'},
							]
							const data = props.data.map(
								({
									user_created_at,
									user_name,
									user_mobile,
									user_address,
									kyc_kyc_verified,
									kyc_volunteer_name,
								}) => ({
									user_created_at:
										moment(user_created_at).format('MMM Do YYYY'),
									user_name,
									user_mobile,
									user_address,
									city: user_address?.split(',').slice(-3)[0],
									kyc_kyc_verified:
										kyc_kyc_verified == null
											? 'Not Requested'
											: kyc_kyc_verified == 1
											? 'Verified'
											: 'Pending',
									kyc_volunteer_name,
								})
							)
							return <ExportToExcel labels={labels} data={data} />
						}
						if (props.action.icon === 'edit') {
							return (
								<Button
									className={classes.button}
									variant='contained'
									color='primary'
									onClick={(e) => {
										setFarmerData(props.data[0])
										setOpenFarmerEdit(true)
									}}
									disabled={props.data.length > 1}
								>
									Edit
								</Button>
							)
						}
						return ''
					},
				}}
				isLoading={loading}
				actions={[
					{icon: 'assign'},
					{icon: 'view'},
					{icon: 'export'},
					{icon: 'edit'},
				]}
				options={{
					actionsColumnIndex: -1,
					selection: true,
				}}
				data={data}
				title=''
			/>
			{selectedFarmerIds.length > 0 && (
				<AssignVolunteer
					selectedFarmerIds={selectedFarmerIds}
					open={open}
					setOpen={setOpen}
					refresh={getData}
				/>
			)}
			<ReassignVolunteer
				selectedFarmerId={farmerIdToReassign}
				open={openReassign}
				setOpen={setOpenReassign}
				refresh={getData}
				isVerified={isVerified}
			/>
			<EditFarmer
				data={farmerData}
				open={openFarmerEdit}
				setOpen={setOpenFarmerEdit}
				refresh={getData}
			/>
		</div>
	)
}

export default Farmers

const CustomChip = ({label, color}) => {
	return (
		<Chip
			size='small'
			label={label}
			style={{
				background: color,
				color: 'white',
				border: 0,
				paddingTop: '2px',
			}}
		/>
	)
}
