import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { http } from '../../api/http'
import Post from './Post'

function School() {
	const [data, setData] = useState([])
	const [loading, setLoading] = useState(false)
	const [sortOrder, setSortOrder] = useState('desc')

	useEffect(() => {
		getData()
	}, [sortOrder])

	function getData() {
		setLoading(true)
		http
			.get(`/aqua-school?order=${sortOrder}`)
			.then((res) => {
				setData(res.data)
				setLoading(false)
			})
			.catch((err) => {
				setLoading(false)
				console.log(err)
			})
	}

	if (loading) return <CircularProgress />

	return (
		<Box>
			<Box display='flex' justifyContent='space-between' alignItems='center'>
				<Typography variant='h6' component='h1'>
					Posts
				</Typography>
				<Box display='flex' alignItems='center' gridGap={20}>
					<Box>
						<Link to='/school/add'>
							<Button variant='contained' color='primary' size='large'>
								Add Aqua School Post
							</Button>
						</Link>
					</Box>
				</Box>
			</Box>
			{data?.map((item) =>
				item?.topics?.length ? (
					<Box mt={3}>
						<Typography color='primary' variant='h6'>
							{item?.name}
						</Typography>
						<Box mt={2} display='flex' gridGap={20} flexWrap='wrap'>
							{item?.topics?.map((item, index) => (
								<Post data={item} key={index} />
							))}
						</Box>
					</Box>
				) : null
			)}
		</Box>
	)
}

export default School
