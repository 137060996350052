import ReactExport from 'react-data-export'
import Button from '@material-ui/core/Button'
const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn

const ExportToExcel = ({ labels, data }) => {
	return (
		<ExcelFile
			element={
				<Button
					variant='contained'
					color='primary'
					style={{ marginLeft: '10px' }}
				>
					Export
				</Button>
			}
		>
			<ExcelSheet data={data} name='Employees'>
				{labels.map((label) => {
					return <ExcelColumn label={label.title} value={label.key} />
				})}
			</ExcelSheet>
		</ExcelFile>
	)
}

export default ExportToExcel
