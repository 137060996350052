import { Box } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";
import React from "react";

function CustomPagination({ page, setPage, count, setOffset }) {
  const handleChange = (event, value) => {
    setOffset((value - 1) * 8);
    setPage(value);
  };

  return (
    <Box bgcolor="white" p={2} pt={4} display="flex" justifyContent="center">
      <Pagination
        variant="outlined"
        color="primary"
        shape="rounded"
        count={+count > 8 ? Math.ceil(+count / 8) : 1}
        page={page}
        onChange={handleChange}
      />
    </Box>
  );
}

export default CustomPagination;
