import React, { useEffect, useState } from "react";
import MaterialTable from "material-table";
import { Box, Button } from "@material-ui/core";
import AddTag from "./AddTag";
import EditTag from "./EditTag";
import { http } from "../../api/http";
import { useSnackbar } from "notistack";

function Tags() {
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    setLoading(true);
    http
      .get("tags")
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleDelete(e, rowData) {
    let confirm = window.confirm("Are you sure you want to delete this?");
    if (!confirm) return;
    http
      .delete(`/tags/${rowData?.id}`)
      .then((res) => {
        enqueueSnackbar("Tag Deleted", { variant: "success" });
        getData();
      })
      .catch((err) => {
        enqueueSnackbar("Something went wrong");
      });
  }

  return (
    <div className="custom_table">
      <Box mb={2} textAlign="right">
        <Button
          onClick={() => setOpen(true)}
          variant="contained"
          color="primary"
        >
          Add Tag
        </Button>
      </Box>
      <MaterialTable
        columns={[{ title: "Name", field: "name" }]}
        data={data}
        isLoading={loading}
        title="Tags"
        actions={[
          {
            icon: "edit",
            onClick: (e, rowData) => {
              setSelectedData(rowData);
              setEditOpen(true);
            },
          },
          {
            icon: "delete",
            onClick: handleDelete,
          },
        ]}
        options={{
          actionsColumnIndex: -1,
        }}
      />
      <AddTag refresh={getData} open={open} setOpen={setOpen} />
      <EditTag
        data={selectedData}
        refresh={getData}
        open={editOpen}
        setOpen={setEditOpen}
      />
    </div>
  );
}

export default Tags;
