import { Box, Avatar, Typography, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import Menu from "@material-ui/core/Menu";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { http } from "../../api/http";
import { useSnackbar } from "notistack";

function Post({ data, refresh }) {
  const { enqueueSnackbar } = useSnackbar();
  const router = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  let [fileIndex, setFileIndex] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleDelete() {
    let confirm = window.confirm("Are you sure you want to delete this?");
    if (!confirm) return;
    http
      .delete(`/feed/${data?.id}`)
      .then((res) => {
        enqueueSnackbar("Post Deleted", { variant: "success" });
        handleClose();
        refresh();
        window.location.reload();
      })
      .catch((err) => {
        enqueueSnackbar("something went wrong", { variant: "error" });
      });
  }

  return (
    <Box mt={2} boxShadow="0px 5px 15px #0000000F" maxWidth={450} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        aligndatas="center"
        p={2}
      >
        <Box display="flex" gridGap={10}>
          <Avatar style={{ background: "#ff5722", color: "white" }}>
            {data?.category?.slice(0, 2)}
          </Avatar>
          <div>
            <Typography variant="body2">{data?.category}</Typography>
            <Typography color="textSecondary" variant="caption">
              {moment(data?.createdAt).startOf().fromNow()}
            </Typography>
          </div>
        </Box>
        <div>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <MenuItem onClick={() => router.push(`/feed/${data?.id}/edit`)}>
              Edit
            </MenuItem>
            <MenuItem onClick={handleDelete}>Delete</MenuItem>
          </Menu>
        </div>
      </Box>
      {data?.fileUrl?.length ? (
        <>
          <Box>
            <img
              src={data?.fileUrl ? data?.fileUrl[fileIndex] : ""}
              style={{ width: "100%", height: 250, objectFit: "cover" }}
              alt=""
            />
          </Box>
          {data?.fileUrl?.length > 1 && (
            <Box mt={1} display="flex" gridGap={3} justifyContent="center">
              {data?.fileUrl?.map((item, index) => (
                <Box
                  style={{ cursor: "pointer" }}
                  width={8}
                  height={8}
                  onClick={() => setFileIndex(index)}
                  bgcolor={
                    fileIndex == index ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.3)"
                  }
                  borderRadius="50%"
                ></Box>
              ))}
            </Box>
          )}
        </>
      ) : null}

      {data?.videoUrl?.length ? (
        <>
          <Box>
            <iframe
              width="100%"
              allowFullScreen
              height={250}
              src={data?.videoUrl ? data?.videoUrl[fileIndex] : ""}
              frameborder="0"
            ></iframe>
          </Box>
          {data?.videoUrl?.length > 1 && (
            <Box mt={1} display="flex" gridGap={3} justifyContent="center">
              {data?.videoUrl?.map((item, index) => (
                <Box
                  style={{ cursor: "pointer" }}
                  width={8}
                  height={8}
                  onClick={() => setFileIndex(index)}
                  bgcolor={
                    fileIndex == index ? "rgba(0,0,0,0.8)" : "rgba(0,0,0,0.3)"
                  }
                  borderRadius="50%"
                ></Box>
              ))}
            </Box>
          )}
        </>
      ) : null}

      <Box p={1}>
        <Typography variant="body2" gutterBottom>
          {data?.content}
        </Typography>
        <Typography color="textSecondary" variant="caption">
          {data?.likesCount || 0} likes, {data?.commentsCount || 0} comments
        </Typography>
      </Box>
    </Box>
  );
}

export default Post;
