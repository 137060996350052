import {
	Box,
	Button,
	Checkbox,
	CircularProgress,
	FormControlLabel,
	makeStyles,
	TextField,
	Typography,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useEffect } from 'react'
import { useState } from 'react'
import { http } from '../../api/http'
import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
	textField: {
		width: '100%',
		marginBottom: '30px',
	},
}))

const CustomTextField = ({ ...restProps }) => {
	const classes = useStyles()

	return (
		<TextField
			className={classes.textField}
			variant='outlined'
			required
			size='small'
			{...restProps}
		/>
	)
}

const NewNotification = ({ selectedGroups }) => {
	const history = useHistory()
	const { enqueueSnackbar } = useSnackbar()
	const [formData, setFormData] = useState({
		title: '',
		message: '',
		sendAll: false,
		selectedGroups: selectedGroups,
	})
	const [groups, setGroups] = useState()
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		getData()
	}, [])

	async function getData() {
		setLoading(true)
		const res = await http.get(`/group`)
		setGroups(res.data)
		setLoading(false)
	}

	const handlePostNotification = e => {
		e.preventDefault()
		http
			.post('/admin-notifications', {
				groupIds: formData.selectedGroups?.map(item => item.id),
				notification: {
					title: formData.title,
					body: formData.message,
				},
				data: {},
				sendAll: formData.sendAll,
			})
			.then(res => {
				enqueueSnackbar('Notification Sent', { variant: 'success' })
				history.push('/notifications')
			})
			.catch(err => {
				enqueueSnackbar('Something went wrong')
			})
	}

	const handleChange = e => {
		setFormData(prev => ({
			...prev,
			[e.target.name]: e.target.value,
		}))
	}
	if (loading) return <CircularProgress />

	return (
		<div>
			<Typography variant='h5' component='h1'>
				New Notification
			</Typography>
			<Box mt={2}>
				<form onSubmit={handlePostNotification}>
					{groups && (
						<Box>
							<Autocomplete
								multiple
								options={groups}
								getOptionLabel={option => option.name}
								size='small'
								defaultValue={formData.selectedGroups}
								getOptionSelected={(option, value) => option.id === value.id}
								onChange={(e, newValue) =>
									setFormData(prev => ({
										...prev,
										selectedGroups: [...newValue],
									}))
								}
								renderInput={params => (
									<CustomTextField
										{...params}
										required={false}
										label='Select Group(s)'
									/>
								)}
							/>
						</Box>
					)}

					<FormControlLabel
						style={{ marginBottom: '20px', marginTop: '-10px' }}
						control={
							<Checkbox
								checked={formData.sendAll}
								onChange={() =>
									setFormData(prev => ({
										...prev,
										sendAll: !prev.sendAll,
									}))
								}
							/>
						}
						label='Select All Farmers'
					/>

					<CustomTextField
						label='Title'
						name='title'
						value={formData.title}
						onChange={handleChange}
					/>

					<CustomTextField
						label='Message'
						name='message'
						rows={4}
						multiline
						value={formData.message}
						onChange={handleChange}
					/>
					<Button variant='contained' color='primary' type='submit'>
						Send
					</Button>
				</form>
			</Box>
		</div>
	)
}

export default NewNotification
