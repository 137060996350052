import {
  Box,
  Card,
  Chip,
  Divider,
  Typography,
  Link as MatLink,
} from "@material-ui/core";
import moment from "moment";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { labelColor } from "../../../shared/constants";

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: "360px",
    width: "28vw",
    marginRight: "30px",
    marginTop: "20px",
    padding: 20,
    marginLeft: "5px",
  },
  viewBtn: {
    padding: "1ch 2ch",
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "3ch",
  },
}));

const ActiveTicketCard = ({ cardData, selectedTicket }) => {
  const history = useHistory();
  const {
    id,
    name,
    createdAt,
    status,
    ticketId,
    userId,
    callNow,
    timeSlot,
    slotDate,
  } = cardData;
  const classes = useStyles();

  return (
    <Card
      variant="outlined"
      className={classes.root}
      style={{
        boxShadow: `${selectedTicket == id ? "0 0  2px 1px  #BF344E" : ""}`,
        transitionDuration: "200ms",
        transitionTimingFunction: "ease",
      }}
    >
      {/* <Link to={`${pathname + search}/${id}/timeline`}> */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Box>
          <Typography variant="caption" component="h4" color="textSecondary">
            Issue Id
          </Typography>
          <Typography variant="body1" component="p" color="textPrimary">
            {ticketId}
          </Typography>
        </Box>
        <Chip
          size="small"
          label={status}
          variant="default"
          style={{
            color: "white",
            background: labelColor(status),
            fontWeight: 600,
            alignItems: "center",
			padding:0
          }}
        />
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" my={1}>
        <Box>
          <Typography variant="caption" component="h4" color="textSecondary">
            Farmer Name
          </Typography>
          <MatLink
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(`/farmers/${userId}`);
            }}
          >
            {name}
          </MatLink>
        </Box>
        <Box>
          <Typography variant="caption" component="h4" color="textSecondary">
            Requested Date
          </Typography>
          <Typography variant="body2" component="p" color="textPrimary">
            {`${moment(createdAt).format("Do MMMM YYYY")} at ${moment(
              createdAt
            ).format("LT")}`}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gridGap={30} mt={2}>
        <Box>
          <Box>
            <Typography variant="caption" component="h4" color="textSecondary">
              Priority
            </Typography>
            <Typography variant="body2" component="p" color="textPrimary">
              {callNow ? "Call Now" : "Call Later"}
            </Typography>
          </Box>
        </Box>
        {!callNow && (
          <>
            <Box>
              <Typography
                variant="caption"
                component="h4"
                color="textSecondary"
              >
                Slot Date
              </Typography>
              <Typography variant="body2" component="p" color="textPrimary">
                {slotDate}
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="caption"
                component="h4"
                color="textSecondary"
              >
                Slot Time
              </Typography>
              <Typography variant="body2" component="p" color="textPrimary">
                {timeSlot}
              </Typography>
            </Box>
          </>
        )}
      </Box>
      <Box mt={3}>
        <Link to={`/tickets/details/${id}`} className={classes.viewBtn}>
          View Issue details
        </Link>
      </Box>
      {/* </Link> */}
    </Card>
  );
};

export default ActiveTicketCard;
