import {
	Box,
	Button,
	Dialog,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
	Typography,
} from '@material-ui/core'
import { useSnackbar } from 'notistack'
import { http } from '../../api/http'
import CustomTextField from '../../shared/components/CustomTextField'
import { useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { roles } from '../../shared/constants'

const AddUser = ({ open, setOpen, refresh }) => {
	const { enqueueSnackbar } = useSnackbar()
	const [user, setUser] = useState({
		name: '',
		mobile: '',
		password: '',
		role: [],
	})

	const handleSubmit = async (e) => {
		e.preventDefault()
		try {
			if (['Expert', 'QC Expert'].every((i) => user.role.includes(i))) {
				return enqueueSnackbar(
					'User cannot have both Expert and QC Expert Roles',
					{ variant: 'error' }
				)
			}

			const res = await http.post('/users/create', user)

			res && enqueueSnackbar('User Added', { variant: 'success' })
			setOpen(false)
			refresh()
		} catch (err) {
			enqueueSnackbar('Error Creating User', { variant: 'error' })
		}
	}

	function handleChange(e) {
		setUser({
			...user,
			[e.target.name]: e.target.value,
		})
	}

	return (
		<div>
			<Dialog open={open} maxWidth='sm' fullWidth>
				<form onSubmit={handleSubmit}>
					<Box p={2}>
						<Typography gutterBottom variant='subtitle1'>
							Add User
						</Typography>
						<Box mt={2} display='flex' flexDirection='column' gridGap={20}>
							<CustomTextField
								onChange={handleChange}
								required
								label='Name'
								name='name'
							/>
							<CustomTextField
								onChange={handleChange}
								required
								label='Mobile'
								name='mobile'
							/>
							<CustomTextField
								onChange={handleChange}
								required
								label='Password'
								name='password'
							/>

							<Box>
								<Autocomplete
									multiple
									options={roles}
									getOptionLabel={(option) => option}
									size='small'
									onChange={(e, newValue) =>
										setUser((prev) => ({
											...prev,
											role: [...newValue],
										}))
									}
									renderInput={(params) => (
										<CustomTextField
											{...params}
											required={false}
											label='Select Role(s)'
										/>
									)}
								/>
							</Box>
						</Box>
						<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
							<Button
								onClick={() => setOpen(false)}
								color='primary'
								variant='outlined'
							>
								Cancel
							</Button>
							<Button color='primary' variant='contained' type='submit'>
								Submit
							</Button>
						</Box>
					</Box>
				</form>
			</Dialog>
		</div>
	)
}

export default AddUser
