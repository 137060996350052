import { Box, Button, Dialog, TextField, Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { http } from '../../api/http'
import { useSnackbar } from 'notistack'
import Autocomplete from '@material-ui/lab/Autocomplete'

const AssignVolunteer = ({ open, setOpen, refresh, selectedFarmerIds }) => {
	const [volunteers, setVolunteers] = useState([])
	const [volunteerId, setVolunteerId] = useState()
	const [farmerIds, setFarmerIds] = useState(selectedFarmerIds)
	const { enqueueSnackbar } = useSnackbar()
	function getFieldVolunteers() {
		http
			.get('/users/field-volunteers')
			.then((res) => {
				setVolunteers(res.data)
			})
			.catch((err) => console.log(err))
	}

	useEffect(() => {
		getFieldVolunteers()
	}, [])

	useEffect(() => {
		setFarmerIds(selectedFarmerIds)
	}, [selectedFarmerIds])

	function handleSubmit(e) {
		e.preventDefault()
		http
			.post(`kyc/volunteer/${volunteerId}`, farmerIds)
			.then(() => {
				enqueueSnackbar('Technical Executive Assigned', { variant: 'success' })
				setOpen(false)
				refresh()
			})
			.catch((err) => {
				err &&
					enqueueSnackbar('Failed to Asssign Technical Executive', {
						variant: 'error',
					})
			})
	}

	return (
		<div>
			<Dialog open={open} maxWidth='sm' fullWidth>
				<form onSubmit={handleSubmit}>
					<Box p={2}>
						<Typography gutterBottom variant='subtitle1'>
							Technical Executives
						</Typography>
						<Box mt={2}>
							<Autocomplete
								id='tags-outlined'
								options={volunteers?.map((item) => ({
									name: item.name,
									id: item.id,
								}))}
								onChange={(e, newValue) => {
									console.log(e.target)
									setVolunteerId(newValue?.id)
								}}
								getOptionLabel={(option) => option.name}
								filterSelectedOptions
								size='small'
								renderInput={(params) => (
									<TextField {...params} variant='outlined' label='Assign' />
								)}
							/>
						</Box>
						<Box display='flex' gridGap={10} justifyContent='flex-end' mt={3}>
							<Button
								onClick={() => {
									setOpen(false)
								}}
								color='primary'
								variant='outlined'
							>
								Cancel
							</Button>
							<Button color='primary' variant='contained' type='submit'>
								Submit
							</Button>
						</Box>
					</Box>
				</form>
			</Dialog>
		</div>
	)
}

export default AssignVolunteer
