import {
	Box,
	Button,
	CircularProgress,
	Link,
	makeStyles,
	Paper,
	Typography,
} from '@material-ui/core'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { http } from '../../api/http'
import Pond from './Pond'
import defaultImage from '../../assets/images/default-image.png'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	container: {
		display: 'flex',
		gap: '20px',
	},
	farmer: {
		flex: 2,
		padding: '15px',
		display: 'flex',
		flexDirection: 'column',
		gap: '15px',
	},
	ponds: {
		flex: 3,
		padding: '15px',
	},
	profileImage: {
		borderRadius: '5px',
		objectFit: 'cover',
		margin: '10px 0',
	},
}))

const FarmerDetails = () => {
	const classes = useStyles()
	const history = useHistory()
	const { id: userId } = useParams()
	const [farmerDetails, setFarmerDetails] = useState({})
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		setLoading(true)
		http.get(`/farmer-details/${userId}`).then((res) => {
			setFarmerDetails(res.data)
			setLoading(false)
		})
		//eslint-disable-next-line
	}, [])

	if (loading) return <CircularProgress />

	return (
		<div>
			<>
				<Box
					mb={2}
					display='flex'
					justifyContent='space-between'
					alignItems='center'
				>
					<Link
						color='textPrimary'
						onClick={() => history.goBack()}
						style={{ cursor: 'pointer', display: 'inline-block' }}
					>
						<Box mb={2} display='flex' alignItems='center' gridGap='10px'>
							<KeyboardBackspaceIcon />
							<Typography variant='subtitle2'>Farmer Profile</Typography>
						</Box>
					</Link>
					<Button
						variant='contained'
						color='primary'
						onClick={() =>
							history.push(`/tickets/?status=active&farmerId=${userId}`)
						}
					>
						View Tickets
					</Button>
				</Box>
				<Box className={classes.container}>
					<Paper variant='outlined' className={classes.farmer}>
						<Box>
							<Typography
								variant='caption'
								component='h3'
								color='textSecondary'
							>
								Personal Details
							</Typography>
							<img
								className={classes.profileImage}
								width={150}
								height={150}
								src={farmerDetails.farmerPicUrl || defaultImage}
								onError={(e) => (e.target.src = defaultImage)}
								alt={farmerDetails?.name}
							/>
						</Box>
						<Box>
							<Typography
								variant='caption'
								component='h3'
								color='textSecondary'
							>
								Full Name
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{farmerDetails?.name}
							</Typography>
						</Box>
						<Box>
							<Typography
								variant='caption'
								component='h3'
								color='textSecondary'
							>
								Mobile Number
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{farmerDetails?.mobile || '-'}
							</Typography>
						</Box>
						<Box>
							<Typography
								variant='caption'
								component='h3'
								color='textSecondary'
							>
								Address
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{farmerDetails?.address || '-'}
							</Typography>
						</Box>
						<Box>
							<Typography
								variant='caption'
								component='h3'
								color='textSecondary'
							>
								AADHAR Number
							</Typography>
							<Typography variant='body1' component='p' color='textPrimary'>
								{farmerDetails?.aadharNumber || '-'}
							</Typography>
						</Box>
						<Box>
							<Box display='flex' gridGap='20px' alignItems='center' mb={1}>
								<Typography
									variant='caption'
									component='h3'
									color='textSecondary'
								>
									AADHAR Image
								</Typography>
							</Box>
							<a
								href={farmerDetails.aadharPicUrl}
								download={farmerDetails.aadharPic}
							>
								<img
									width={250}
									src={farmerDetails.aadharPicUrl || defaultImage}
									onError={(e) => (e.target.src = defaultImage)}
									alt={farmerDetails?.name}
								/>
							</a>
						</Box>
					</Paper>

					<Paper variant='outlined' className={classes.ponds}>
						<Typography variant='caption' component='h3' color='textSecondary'>
							Ponds
						</Typography>
						<Box mt={2}>
							{farmerDetails.ponds?.map((item, index) => (
								<Pond key={item.id} data={item} index={index} />
							))}
						</Box>
					</Paper>
				</Box>
			</>
		</div>
	)
}

export default FarmerDetails
