import { Box, CircularProgress, Typography } from '@material-ui/core'
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined'
import { useState } from 'react'
import { http } from '../../api/http'

function CustomUpload({ name, label = 'Tap to upload', onChange }) {
	let [fileName, setFileName] = useState(null)
	const [loading, setLoading] = useState(false)

	function handleChange(e) {
		if (e.target.files[0]) {
			const formData = new FormData()
			formData.append('file', e.target.files[0])
			setLoading(true)
			http
				.post('/upload', formData)
				.then((res) => {
					setFileName(e.target.files[0].name)
					onChange(res.data)
					setLoading(false)
				})
				.catch((err) => {
					setLoading(false)
					console.log(err)
				})
		}
	}

	return (
		<>
			<label htmlFor={name} style={{ cursor: 'pointer' }}>
				<Box
					borderRadius={8}
					textAlign='center'
					py={2}
					width='100%'
					border='1px dotted rgba(0,0,0,0.3)'
				>
					{loading ? (
						<CircularProgress />
					) : (
						<>
							<PublishOutlinedIcon color='disabled' />
							<Typography variant='body2' color='textSecondary'>
								{fileName ? fileName : label}
							</Typography>
						</>
					)}
				</Box>
			</label>
			<input
				onChange={handleChange}
				type='file'
				style={{ display: 'none' }}
				id={name}
			/>
		</>
	)
}

export default CustomUpload
