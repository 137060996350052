import {Box, CircularProgress, Typography} from '@material-ui/core'
import {useState} from 'react'
import {useEffect} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {http} from '../../../api/http'
import {makeStyles} from '@material-ui/core/styles'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace'
import PhotoDialog from '../../../shared/components/PhotoDialog'

const useStyles = makeStyles((theme) => ({
	downloadBtn: {
		marginTop: '10px',
		alignItems: 'center',
	},
}))

const TicketDetails = () => {
	const classes = useStyles()
	const {id} = useParams()
	const history = useHistory()

	const [data, setData] = useState({})
	const [loading, setLoading] = useState(false)
	const [selectedPhoto, setSelectedPhoto] = useState()
	const [dialogOpen, setDialogOpen] = useState(false)

	const {
		ticketId,
		pondDetails,
		animalVariety,
		type: animalType,
		timeSlot,
		issuesDetails,
		waterReport,
		pondPicUrls,
		animalPicUrls,
		waterReportFileUrl,
	} = data
	useEffect(() => {
		setLoading(true)
		getData()
	}, [])
	console.log({waterReport})

	function getData() {
		http
			.get(`/request-help/${id}`)
			.then((res) => {
				setData(res.data)
				console.log(res.data)
				setLoading(false)
			})
			.catch((err) => console.log(err))
	}

	if (loading) return <CircularProgress />

	return (
		<div>
			<Box display='flex' alignItems='center' gridGap={20}>
				<Typography
					onClick={() => history.goBack()}
					style={{cursor: 'pointer'}}
				>
					<KeyboardBackspaceIcon />
				</Typography>
				<Typography>{`Issue details (Issue Id: ${ticketId})`}</Typography>
			</Box>
			<Box mb={2}>
				<Typography variant='h6' component='h1'>
					Issue Details
				</Typography>
			</Box>

			<Box display='flex' flexDirection='column' gridGap={15}>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Selected Ponds/Tanks (10)
					</Typography>
					<Box>
						{pondDetails?.map((item, index) => {
							if (index < 3)
								return (
									<Box key={item.id}>
										<Typography
											variant='body1'
											component='p'
											color='textPrimary'
										>
											{`Pond ${index + 1}`}
										</Typography>
										<Typography
											variant='body2'
											component='p'
											color='textPrimary'
										>
											{item.address}
										</Typography>
									</Box>
								)
						})}
					</Box>
				</Box>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Animal Type and Variety
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{animalType} | {animalVariety}
					</Typography>
				</Box>
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Issue Type
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{issuesDetails?.map((item) => (
							<li>{item.name}</li>
						))}
					</Typography>
				</Box>

				{pondPicUrls?.length > 0 && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Pond Image
						</Typography>
						<Box mt={1} display='flex' gridGap={20}>
							{pondPicUrls?.length > 0 &&
								pondPicUrls?.map((item, index) => (
									<img
										src={item}
										alt={item}
										width={300}
										height={200}
										style={{objectFit: 'cover'}}
										onClick={() => {
											setSelectedPhoto(item)
											setDialogOpen(true)
										}}
									/>
								))}
						</Box>
					</Box>
				)}
				{animalPicUrls?.length > 0 && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Animal Images
						</Typography>
						<Box mt={1} display='flex' gridGap={20}>
							{animalPicUrls?.length > 0 &&
								animalPicUrls?.map((item, index) => (
									<img
										src={item}
										alt={item}
										width={300}
										height={200}
										onClick={() => {
											setSelectedPhoto(item)
											setDialogOpen(true)
										}}
									/>
								))}
						</Box>
					</Box>
				)}
				{waterReport?.length && (
					<Box>
						<Typography variant='body2' component='h3' color='textSecondary'>
							Water Report
						</Typography>
						<Box mt={1} display='flex' gridGap={20}>
							{waterReportFileUrl?.length > 0 &&
								waterReportFileUrl?.map((item, index) => (
									<img
										src={item}
										alt={item}
										width={300}
										height={200}
										style={{objectFit: 'cover'}}
										onClick={() => {
											setSelectedPhoto(item)
											setDialogOpen(true)
										}}
									/>
								))}
						</Box>
					</Box>
				)}
				<Box>
					<Typography variant='body2' component='h3' color='textSecondary'>
						Scheduled Call
					</Typography>
					<Typography variant='body1' component='p' color='textPrimary'>
						{timeSlot}
					</Typography>
				</Box>
			</Box>
			<PhotoDialog
				setOpen={setDialogOpen}
				open={dialogOpen}
				imageUrl={selectedPhoto}
			/>
		</div>
	)
}

export default TicketDetails
