import { useContext } from 'react'
import { createContext } from 'react'

const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
	// const parseJwt = (token) => {
	// 	try {
	// 		return JSON.parse(Buffer.from(token, 'base64')).split('.')[1]
	// 	} catch {
	// 		return null
	// 	}
	// }

	// const checkAuth = () => {
	// 	if (!localStorage.getItem('access_token')) return false

	// 	const decodedJwt = parseJwt(
	// 		localStorage.getItem('access_token').split(' ')[1]
	// 	)
	// 	console.log(decodedJwt)

	// 	if (decodedJwt?.exp * 1000 < Date.now()) return false

	// 	return true
	// }

	return (
		<AuthContext.Provider
			value={
				{
					// checkAuth,
				}
			}
		>
			{children}
		</AuthContext.Provider>
	)
}

export const useAuthContext = () => useContext(AuthContext)

export default AuthProvider
